import store from "@/store/store";
import {StoreNamespace} from "@/store/types";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {gamesService} from "@/services";
import {Result} from "typescript-result";
import {VuexState} from "@/core/vuex/types";
import {ITournamentForm} from "@/core/tournaments/tournament-form.entity";
import {
    CreateTournamentModuleGetterTypes, CreateTournamentsModuleMutationTypes,
    ICreateTournamentModuleState
} from "@/store/modules/tournaments/createTournament/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.CreateTournament,
    store
})
class CreateTournamentModule extends VuexModule implements ICreateTournamentModuleState {
    tournamentForm: ITournamentForm | null = null
    currentVuexState: VuexState = VuexState.LOADING

    get [CreateTournamentModuleGetterTypes.TOURNAMENT_FORM] () { return this.tournamentForm }

    get [CreateTournamentModuleGetterTypes.CURRENT_VUEX_STATE] () { return this.currentVuexState }

    @Mutation
    [CreateTournamentsModuleMutationTypes.SET_TOURNAMENT_FORM] (tournamentForm: ITournamentForm | null) {
        this.tournamentForm = tournamentForm
    }

    @Mutation
    [CreateTournamentsModuleMutationTypes.SET_CURRENT_VUEX_STATE] (value: VuexState) {
        this.currentVuexState = value
    }

    @Action
    public async setTournamentForm(gameId: number): Promise<Result<[], ITournamentForm>> {
        const getTournamentFormResult = await gamesService.getTournamentForm(gameId)

        if (getTournamentFormResult.isFailure()) {
            this.context.commit(CreateTournamentsModuleMutationTypes.SET_TOURNAMENT_FORM, null)
            this.context.commit(CreateTournamentsModuleMutationTypes.SET_CURRENT_VUEX_STATE, VuexState.DATA_NOT_FOUND)
            return Result.error([])
        }

        this.context.commit(CreateTournamentsModuleMutationTypes.SET_TOURNAMENT_FORM, getTournamentFormResult.value)
        this.context.commit(CreateTournamentsModuleMutationTypes.SET_CURRENT_VUEX_STATE, VuexState.INITIALIZED)
        return Result.ok(getTournamentFormResult.value)
    }

    @Action
    public clearStore () {
        this.context.commit(CreateTournamentsModuleMutationTypes.SET_CURRENT_VUEX_STATE, VuexState.LOADING)
        this.context.commit(CreateTournamentsModuleMutationTypes.SET_TOURNAMENT_FORM, null)
    }
}

export const CreateTournamentStoreModule = getModule(CreateTournamentModule)