import {BaseAxiosService} from "@/core/service/base.service";
import {
    AuthServiceUrlPaths,
    ICustomTokenResponse,
    ISignInWithEmailAndPasswordRequest, ISignInWithProviderRequest,
    ISignUpWithEmailAndPasswordRequest
} from "@/services/auth/types";
import {IServiceError} from "@/core/service/types";
import {Result} from "typescript-result";

export class AuthService extends BaseAxiosService {

    constructor() {
        super(process.env.VUE_APP_AUTH_SERVICE_URL as string)
    }

    public static create () {
        return new AuthService()
    }

    public signInWithEmailAndPassword (content: ISignInWithEmailAndPasswordRequest): Promise<Result<IServiceError, ICustomTokenResponse>> {
        return this.executeWithPostContent<ICustomTokenResponse>(
            AuthServiceUrlPaths.SignInWithEmailAndPasswordUrl, content
        )
    }

    public signUpWithEmailAndPassword (content: ISignUpWithEmailAndPasswordRequest): Promise<Result<IServiceError, ICustomTokenResponse>> {
        return this.executeWithPostContent<ICustomTokenResponse>(
            AuthServiceUrlPaths.SignUpWithEmailAndPasswordUrl, content
        )
    }

    public signInWithGoogle (content: ISignInWithProviderRequest): Promise<Result<IServiceError, ICustomTokenResponse>> {
        return this.executeWithPostContent<ICustomTokenResponse>(
            AuthServiceUrlPaths.SignInWithGoogleUrl, content
        )
    }

    public signInWithDiscord (content: ISignInWithProviderRequest): Promise<Result<IServiceError, ICustomTokenResponse>> {
        return this.executeWithPostContent<ICustomTokenResponse>(
            AuthServiceUrlPaths.SignInWithDiscordUrl, content
        )
    }

    public signInWithTwitch (content: ISignInWithProviderRequest): Promise<Result<IServiceError, ICustomTokenResponse>> {
        return this.executeWithPostContent<ICustomTokenResponse>(
            AuthServiceUrlPaths.SignInWithTwitchUrl, content
        )
    }
}