import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const statsRoute: RouteConfig = {
    path: '/stats',
    name: RouteNames.StatsRoute,
    component: () => import('@/views/dashboard/StatsPage.vue'),
    meta: {
        requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
    },
    children: [
        {
            path: 'lol/:name?/:region?',
            component: () => import('@/components/stats/lol/ScoreTracer.vue'),
            name: RouteNames.LolWelcome,
            alias: 'lol/:name/:region',
            meta: {
                requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
            }
        }
    ]
}