






























import {Component, Emit} from "vue-property-decorator";
import Vue from "vue";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {GameType} from "@/core/games/types";
import {LeagueOfLegendsRegions} from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import ConnectLeagueOfLegendsAccountForm from "@/components/GameAccounts/ConnectGameAccount/leagueOfLegends/ConnectLeagueOfLegendsAccountForm.vue";
import {IConnectGameAccountFormData} from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountPopup.vue";
import {ICreateLeagueOfLegendsGameAccountRequest} from "@/services/account/types";

@Component({
  components: {
    ConnectLeagueOfLegendsAccountForm,
    ValidationObserver,
    ValidationProvider
  },
  enums: {
    GameType,
    LeagueOfLegendsRegions
  }
})
export default class ConnectGameAccountForm extends Vue {
  private gameType: GameType = GameType.LeagueOfLegends

  private gameTypes: { gameType: GameType, text: string }[] = [
    { gameType: GameType.LeagueOfLegends, text: 'League Of Legends' }
  ]

  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: any = {

  }

  private mounted () {
    if (this.$refs.connectGameAccountValidationObserver) {
      this.formObserver = this.$refs.connectGameAccountValidationObserver as InstanceType<typeof ValidationObserver>
    }
  }

  private onRequestDataChange (form: ICreateLeagueOfLegendsGameAccountRequest) {
    this.form = form
  }

  @Emit()
  private onSubmitAction (): IConnectGameAccountFormData  {
    return {
      gameType: this.gameType,
      formData: this.form
    }
  }

  @Emit()
  private onCloseAction () {
    return
  }
}
