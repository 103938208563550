







import { Component, Vue, Prop } from 'vue-property-decorator'
import { COLORS } from "@/core/helpers/color.types"

@Component({
    enums: {COLORS}
})
export default class BaseButtonRounded extends Vue {
    @Prop({required: false, default: () => 'default text', type: String}) private readonly btnText!: string;
    @Prop({required: false}) private readonly actionCallback!: () => void;
}

