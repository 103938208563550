















import { Component, Vue } from 'vue-property-decorator'
import {RouteNames} from "@/router/types";

@Component({
  enums: {
    RouteNames
  }
})
export default class SidebarLogo extends Vue {

}
