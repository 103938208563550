
































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {AccountFriendsModuleStoreModule, PopupsStoreModule} from "@/store";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import CircularLoading from "@/components/Loading/CircularLoading.vue";
import {NotificationService} from "@/components/Notifications/notification.service";
import {FriendInvitationState} from "@/core/friends/types";

@Component({
  components: {CircularLoading},
  enums: {FriendInvitationState}
})
export default class AddFriendPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  @Getter(AccountFriendsModuleGetterTypes.INVITED_BY_ACCOUNT, { namespace: StoreNamespace.AccountFriends })
  private readonly invitedByAccount!: IAccountEntity

  private currentState: FriendInvitationState = FriendInvitationState.AccountIsFound
  private addFriendRequestIsProcessing: boolean = false

  private async onAddFriendAction () {
    this.addFriendRequestIsProcessing = true
    const addFriendResult = await AccountFriendsModuleStoreModule.addFriend({
      invitation_code: this.invitedByAccount.invitation_code as string
    })

    if (addFriendResult.isFailure()) {
      this.addFriendRequestIsProcessing = false
      this.currentState = FriendInvitationState.Fail
      NotificationService.showErrorNotification(addFriendResult.error.message)
      setTimeout(() => { this.currentState = FriendInvitationState.AccountIsFound }, 2000)
    } else {
      this.addFriendRequestIsProcessing = false
      this.currentState = FriendInvitationState.Success
      NotificationService.showSuccessNotification(`You are now connected with ${addFriendResult.value.display_name}`)
      setTimeout(() => { PopupsStoreModule.hideActivePopup().then(() => {
        AccountFriendsModuleStoreModule.setInvitedAccount(undefined)
      }) }, 2000)
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
