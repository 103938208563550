import {IPaginationResult} from "@/core/service/types";

export enum VuexState {
    LOADING,
    INITIALIZED,
    DATA_NOT_FOUND,
}

export interface IVuexData<T> {
    data: T | null
    vuexState: VuexState
}

export const defaultVuexData: IVuexData<any> = {
    data: null,
    vuexState: VuexState.LOADING
}

export interface IVuexPaginatedData<T> extends IPaginationResult<T> {
    vuexState: VuexState
}

export const defaultVuexPaginatedData: IVuexPaginatedData<any> = {
    pagination: {
        current_page: 0,
        limit: 5,
        total_pages: 0,
        total_rows: 0
    },
    rows: [],
    vuexState: VuexState.LOADING
}

export interface IUpdatePaginationData<T> {
    dataType: T
    newCurrentPage: number
}