import {IAuthUser} from "@/core/auth/types";
import {firebase} from "@/plugins/firebase";

export interface IAuthModuleState {
    user: IAuthUser | null,
    isNewUser: boolean
    isAuthProcessStarted: boolean

    setupListeners(): void
    signOut (): Promise<void>
    startAuthProcess (): void
    stopAuthProcess (): void
    setIsNewUser (isNewUser: boolean): void
    signInWithProvider (customToken: string): Promise<firebase.auth.UserCredential>
    clearStore (): void
}

export enum AuthModuleGetterTypes {
    IS_LOGGED_IN = 'IS_LOGGED_IN',
    USER = 'USER',
    IS_AUTH_PROCESS_STARTED = 'IS_AUTH_PROCESS_STARTED',
    IS_USER_LOGGED = 'IS_USER_LOGGED',
}

export enum AuthModuleMutationTypes {
    SET_USER_INFO = 'SET_USER_INFO',
    SET_IS_AUTH_PROCESS_STARTED = 'SET_IS_AUTH_PROCESS_STARTED',
    SET_IS_NEW_USER = 'SET_IS_NEW_USER',
}

