import {BaseAxiosService} from "@/core/service/base.service";
import {Result} from "typescript-result";
import {IServiceError} from "@/core/service/types";
import {GamesServiceUrlPaths} from "@/services/games/types";
import {ITournamentForm} from "@/core/tournaments/tournament-form.entity";

export class GamesService extends BaseAxiosService {
    constructor() {
        super(process.env.VUE_APP_GAMES_SERVICE_URL as string)
        this.bindRequestInterceptor()
        this.bindResponseInterceptor()
    }

    public static create () {
        return new GamesService()
    }

    public getTournamentForm (gameId: number): Promise<Result<IServiceError, ITournamentForm>> {
        return this.executeGet<ITournamentForm>(
            GamesServiceUrlPaths.GetTournamentFormURL.replace('{gameId}', gameId.toString())
        )
    }
}