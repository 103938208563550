import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const tournamentsRoute: RouteConfig = {
    path: '/tournaments',
    name: RouteNames.TournamentsRoute,
    component: () => import(/* webpackChunkName: "TournamentsPage" */ '@/views/tournaments/TournamentsPage.vue'),
    redirect: { name: RouteNames.AvailableTournamentsRoute },
    meta: {
        requiresAuth: AuthGuard.MUST_BE_AUTHENTICATED
    },
    children: [
        {
            path: '/tournaments/available',
            name: RouteNames.AvailableTournamentsRoute,
            component: () => import(/* webpackChunkName: "AvailableTournamentsPage" */ '@/views/tournaments/types/AvailableTournamentsPage.vue'),
        },
        {
            path: '/tournaments/participated',
            name: RouteNames.ParticipatedTournamentsRoute,
            component: () => import(/* webpackChunkName: "ParticipatedTournamentsPage" */ '@/views/tournaments/types/ParticipatedTournamentsPage.vue'),
        },
        {
            path: '/tournaments/owned',
            name: RouteNames.OwnedTournamentsRoute,
            component: () => import(/* webpackChunkName: "OwnedTournamentsPage" */ '@/views/tournaments/types/OwnedTournamentsPage.vue'),
        },
        {
            path: '/tournaments/completed',
            name: RouteNames.CompletedTournamentsRoute,
            component: () => import(/* webpackChunkName: "CompletedTournamentsPage" */ '@/views/tournaments/types/CompletedTournamentsPage.vue'),
        }
    ]
}