









import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";

@Component({})
export default class ConnectGameAccountButton extends Vue {
  @Prop({ type: Boolean, required: false, default: () => true }) private readonly block!: boolean
  @Prop({ type: Boolean, required: false, default: () => true }) private readonly xSmall!: boolean
  @Prop({ type: Boolean, required: false, default: () => false }) private readonly small!: boolean

  @Getter(AccountFriendsModuleGetterTypes.FRIENDS, { namespace: StoreNamespace.AccountFriends })
  private readonly friends!: IAccountEntity[]

  get buttonText () {
    if (this.friends.length > 0) {
      return 'Add new friend'
    }

    return 'Add first friend'
  }

  private onAddFriend () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.CopyFriendInvitationLink
    })
  }
}
