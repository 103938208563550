



















import Vue from 'vue';
import {Component} from "vue-property-decorator";
import DefaultHeader from "@/components/Header/DefaultHeader.vue";
import DefaultSidebar from "@/components/Sidebar/DefaultSidebar.vue";
import { Getter } from 'vuex-class'
import {StoreNamespace} from "@/store/types";
import LoadingOverlay from "@/components/Overlays/LoadingOverlay.vue";
import {CoreModuleGetterTypes} from "@/store/modules/core/types";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import Notifications from "@/components/Notifications/Notifications.vue";
import SocialSidebar from "@/components/Sidebar/SocialSidebar.vue";
import PopupsContainer from "@/components/Popups/PopupsContainer.vue";
import { LolStoreModule } from "@/store";
import { COLORS } from "@/core/helpers/color.types"

@Component({
  components: {
    PopupsContainer,
    SocialSidebar,
    Notifications,
    LoadingOverlay,
    DefaultSidebar,
    DefaultHeader
  },
  enums: {
    COLORS
  }
})
export default class App extends Vue {
  @Getter(CoreModuleGetterTypes.IS_INITIALIZED, {namespace: StoreNamespace.Core})
  private readonly isInitialized!: boolean

  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, {namespace: StoreNamespace.Auth})
  private readonly isLoggedIn!: boolean

  private created() {
    LolStoreModule.fetchBaseLolInfo();
  }

  get sidebarBackgroundColor() {
    if (!this.$vuetify.breakpoint.mobile) {
      return COLORS.OPACITY_M;
    }
    return COLORS.PRIMARY;
  }
}
