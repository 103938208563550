






import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  components: {BaseButtonRounded}
})
export default class ConnectGameAccountSmallButton extends Vue {
  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private onAddNewAccount () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.ConnectGameAccount
    })
  }
}
