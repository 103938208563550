import {BaseAxiosService} from "@/core/service/base.service";
import {IPaginationParams, IPaginationResult, IServiceError} from "@/core/service/types";
import {AccountsServiceUrlPaths} from "@/services/accounts/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {Result} from "typescript-result";
import {ITournamentResponse} from "@/services/tournaments/types";

export class AccountsService extends BaseAxiosService {
    constructor() {
        super(process.env.VUE_APP_ACCOUNTS_SERVICE_URL as string)
    }

    public static create () {
        return new AccountsService()
    }

    public getAccountById (accountId: string): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeGet<IAccountEntity>(
            AccountsServiceUrlPaths.GetAccountByIdURL
                .replace('{accountId}', accountId)
        )
    }

    public getAccountByInvitationCode (invitationCode: string): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeGet<IAccountEntity>(
            AccountsServiceUrlPaths.GetAccountByInvitationCodeURL, {
                params: {
                    invitation_code: invitationCode
                }
            })
    }

    public getGameAccountsByAccountId (accountId: string): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeGet<IAccountEntity>(
            AccountsServiceUrlPaths.GetGameAccountsURL
                .replace('{accountId}', accountId)
        )
    }

    public getGameAccountByAccountId (accountId: string, gameAccountId: number): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeGet<IAccountEntity>(
            AccountsServiceUrlPaths.GetGameAccountByIdURL
                .replace('{accountId}', accountId)
                .replace('{gameAccountId}', gameAccountId.toString())
        )
    }

    public getTournamentsByAccountId (accountId: string, pagination?: IPaginationParams): Promise<Result<IServiceError, IPaginationResult<ITournamentResponse[]>>> {
        return this.executeGet<IPaginationResult<ITournamentResponse[]>>(
            AccountsServiceUrlPaths.GetTournamentsByIdURL
                .replace('{accountId}', accountId), { params: pagination ? pagination : {} }
        )
    }
}