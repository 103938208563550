
































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {AccountStoreModule, AuthStoreModule, PopupsStoreModule} from "@/store";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {IUpdateAccountEmailRequest} from "@/services/account/types";
import {NotificationService} from "@/components/Notifications/notification.service";

@Component({
  components: {ValidationObserver, ValidationProvider}
})
export default class UpdateAccountEmailPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  private isProcessing: boolean = false
  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: IUpdateAccountEmailRequest = {
    email: undefined
  }

  private mounted () {
    if (this.$refs.updateEmailValidationObserver) {
      this.formObserver = this.$refs.updateEmailValidationObserver as InstanceType<typeof ValidationObserver>
    }
  }

  private async onUpdateEmailAction () {
    this.isProcessing = true
    const updateEmailResult = await AccountStoreModule.updateEmail(this.form)

    if (updateEmailResult.isFailure()) {
      NotificationService.showErrorNotification(updateEmailResult.error.message)
      if (updateEmailResult.error.errors) {
        this.formObserver.setErrors(updateEmailResult.error.errors)
      }
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully updated email. Please Sign In')
      this.onCloseAction()
      await AuthStoreModule.signOut()
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
