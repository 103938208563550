import axios, { AxiosResponse } from "axios";
import { IQueueData } from "./types";

export class LolStaticApi {
    private static hostName: string = process.env.VUE_APP_LOL_STATIC_API_HOST as string;
    
    private constructor() {}

    public static getQueue(): Promise<AxiosResponse<Array<IQueueData>>> {
        return axios.get(`${this.hostName}/queues.json`);
    }

}