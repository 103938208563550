






import {Component, Vue} from "vue-property-decorator";
import { COLORS } from "@/core/helpers/color.types"

@Component({
  enums: { COLORS }
})
export default class CircularLoading extends Vue {

}
