export interface ISidebarsModuleState {
    isLeftSidebarOpen: boolean
    isRightSidebarOpen: boolean

    toggleLeftSidebar (payload: boolean | null)
    toggleRightSidebar (payload: boolean | null)
    clearStore ()
}

export enum SidebarsModuleGetterTypes {
    IS_LEFT_SIDEBAR_OPEN = 'IS_LEFT_SIDEBAR_OPEN',
    IS_RIGHT_SIDEBAR_OPEN = 'IS_RIGHT_SIDEBAR_OPEN',
}

export enum SidebarsModuleMutationTypes {
    SET_LEFT_SIDEBAR_OPEN = 'SET_LEFT_SIDEBAR_OPEN',
    SET_RIGHT_SIDEBAR_OPEN = 'SET_RIGHT_SIDEBAR_OPEN',
}