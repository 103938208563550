












































import {Component} from "vue-property-decorator";
import Vue from "vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {RouteNames} from "@/router/types";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import AddFriendSmallButton from "@/components/Friends/AddFriendSmallButton.vue";
import AddFriendButton from "@/components/Friends/AddFriendButton.vue";
import GameAccountListItem from "@/components/GameAccounts/GameAccountListItem.vue";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  components: {GameAccountListItem, AddFriendButton, AddFriendSmallButton, BaseButtonRounded},
  enums: {RouteNames}
})
export default class AccountFriendsSocialSidebarList extends Vue {
  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AccountFriendsModuleGetterTypes.FRIENDS, { namespace: StoreNamespace.AccountFriends })
  private readonly friends!: IAccountEntity[]

  private onAddFriend () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.CopyFriendInvitationLink
    })
  }

  
  get buttonText (): string {
    if (this.friends.length > 0) {
      return 'add-new-friend'
    }

    return 'add-first-friend'
  }
}
