import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";

export interface IAccountGameAccountsModuleState {
    gameAccounts: IGameAccountEntity[]
}

export enum AccountGameAccountsModuleGetterTypes {
    GAME_ACCOUNTS = 'GAME_ACCOUNTS',
    FIRST_GAME_ACCOUNT_IS_CONNECTED = 'FIRST_GAME_ACCOUNT_IS_CONNECTED',
}

export enum AccountGameAccountsModuleMutationTypes {
    SET_GAME_ACCOUNTS = 'SET_GAME_ACCOUNTS',
    UPDATE_GAME_ACCOUNT = 'UPDATE_GAME_ACCOUNT',
    DELETE_GAME_ACCOUNT = 'DELETE_GAME_ACCOUNT',
}