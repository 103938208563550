import store from "@/store/store";
import {StoreNamespace} from "@/store/types";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {tournamentsService} from "@/services";
import {Result} from "typescript-result";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {
    defaultVuexData,
    defaultVuexPaginatedData,
    IVuexData,
    IVuexPaginatedData,
    VuexState
} from "@/core/vuex/types";
import {
    CurrentTournamentModuleGetterTypes, CurrentTournamentsModuleMutationTypes,
    ICurrentTournamentModuleState, ISetTournamentData
} from "@/store/modules/tournaments/currentTournament/types";
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import {IPagination} from "@/core/service/types";
import {ITournamentParticipantsResponse} from "@/services/tournaments/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.CurrentTournament,
    store
})
class CurrentTournamentModule extends VuexModule implements ICurrentTournamentModuleState {
    tournamentData: IVuexData<ITournamentEntity> = defaultVuexData
    tournamentLeaderboardData: IVuexPaginatedData<ITournamentParticipantEntity[]> = defaultVuexPaginatedData
    tournamentTopScoreParticipant: ITournamentParticipantEntity | null = null
    tournamentMyParticipation: ITournamentParticipantEntity | null = null
    tournamentCurrentParticipants: number = 0
    dataPoolingInterval: NodeJS.Timeout | null = null

    get [CurrentTournamentModuleGetterTypes.TOURNAMENT] () {
        if (this.tournamentData.data === null) return null

        return {...this.tournamentData.data, ...{
                leaderboard: this.tournamentLeaderboardData.rows,
                my_participation: this.tournamentMyParticipation,
                top_score: this.tournamentTopScoreParticipant,
                current_participants: this.tournamentCurrentParticipants
        }}
    }

    get [CurrentTournamentModuleGetterTypes.TOURNAMENT_VUEX_STATE] () {
        return this.tournamentData.vuexState
    }

    get [CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_PAGINATION] () {
        return this.tournamentLeaderboardData.pagination
    }

    get [CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_VUEX_STATE] () {
        return this.tournamentLeaderboardData.vuexState
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT] (tournament: ITournamentEntity | null) {
        this.tournamentData = {...this.tournamentData, ...{ data: tournament }}
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_VUEX_STATE] (vuexState: VuexState) {
        this.tournamentData = {...this.tournamentData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD] (tournaments: ITournamentParticipantEntity[]) {
        this.tournamentLeaderboardData = {...this.tournamentLeaderboardData, ...{ rows: tournaments }}
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_PAGINATION] (pagination: IPagination) {
        this.tournamentLeaderboardData = {...this.tournamentLeaderboardData, ...{ pagination: pagination }}
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_VUEX_STATE] (vuexState: VuexState) {
        this.tournamentLeaderboardData = {...this.tournamentLeaderboardData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_TOP_SCORE_PARTICIPANT] (topScoreParticipant: ITournamentParticipantEntity | null) {
        this.tournamentTopScoreParticipant = topScoreParticipant
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_MY_PARTICIPATION] (myParticipation: ITournamentParticipantEntity | null) {
        this.tournamentMyParticipation = myParticipation
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_CURRENT_PARTICIPANTS] (value: number) {
        this.tournamentCurrentParticipants = value
    }

    @Mutation
    [CurrentTournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL] (dataPoolingInterval: NodeJS.Timeout | null) {
        this.dataPoolingInterval = dataPoolingInterval
    }

    @Action
    public async initialize (tournamentId: string) {
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_VUEX_STATE, VuexState.LOADING)
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_VUEX_STATE, VuexState.LOADING)
        await this.setTournament(parseInt(tournamentId))
    }

    @Action
    public async setTournament(tournamentId: number): Promise<Result<null, ITournamentEntity>> {
        const getTournamentByIdResult = await tournamentsService.getTournamentById(tournamentId)

        if (getTournamentByIdResult.isFailure()) {
            // set tournament
            this.clearStore()
            return Result.error(null)
        }

        this.setParticipantsDataToStore({
            current_participants: getTournamentByIdResult.value.data.current_participants,
            leaderboard: getTournamentByIdResult.value.data.leaderboard,
            my_participation: getTournamentByIdResult.value.data.my_participation,
            top_score: getTournamentByIdResult.value.data.top_score,
        })

        // set tournament
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT, getTournamentByIdResult.value.tournament)
        setTimeout(() => {
            this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_VUEX_STATE, VuexState.INITIALIZED)
        }, 500)

        this.startDataPooling()
        return Result.ok(getTournamentByIdResult.value.tournament)
    }

    @Action
    public async setTournamentParticipants(data: ISetTournamentData): Promise<Result<null, ITournamentParticipantEntity[]>> {
        const getTournamentParticipantsResult = await tournamentsService.getTournamentParticipants(data.tournamentId, data.pagination)

        if (getTournamentParticipantsResult.isFailure()) {
            // set tournament
            return Result.error(null)
        }

        this.setParticipantsDataToStore(getTournamentParticipantsResult.value)

        if (getTournamentParticipantsResult.value.leaderboard) {
            return Result.ok(getTournamentParticipantsResult.value.leaderboard.rows)
        } else {
            return Result.ok([])
        }
    }

    @Action
    public async refreshCurrentTournamentParticipants(): Promise<Result<null, ITournamentParticipantEntity[]>> {
        const currentTournament = this[CurrentTournamentModuleGetterTypes.TOURNAMENT]
        if (currentTournament == null) return Result.error(null)

        const setTournamentParticipantsResult = await this.setTournamentParticipants({
            tournamentId: currentTournament.id,
            pagination: {
                limit: this[CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_PAGINATION].limit,
                page: this[CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_PAGINATION].current_page
            }
        })
        if (setTournamentParticipantsResult.isFailure()){
            return setTournamentParticipantsResult
        }

        return setTournamentParticipantsResult
    }

    @Action
    public async refreshCurrentTournament(): Promise<Result<null, ITournamentEntity>> {
        const currentTournament = this[CurrentTournamentModuleGetterTypes.TOURNAMENT]
        if (currentTournament == null) return Result.error(null)

        const setTournamentResult = await this.setTournament(currentTournament.id)
        if (setTournamentResult.isFailure()){
            return setTournamentResult
        }

        return setTournamentResult
    }

    @Action
    public async onCurrentPaginationPageChange(newCurrentPage: number) {
        const currentTournament = this[CurrentTournamentModuleGetterTypes.TOURNAMENT]
        if (currentTournament == null) return

        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_VUEX_STATE, VuexState.LOADING)
        await this.setTournamentParticipants({
            tournamentId: currentTournament.id,
            pagination: {
                limit: this[CurrentTournamentModuleGetterTypes.TOURNAMENT_LEADERBOARD_PAGINATION].limit,
                page: newCurrentPage - 1
            }
        })
    }

    @Action
    private setParticipantsDataToStore (data: ITournamentParticipantsResponse) {
        // set leaderboard
        if (data.leaderboard) {
            this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD, data.leaderboard.rows)
            this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_PAGINATION, data.leaderboard.pagination)
            setTimeout(() => {
                this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_VUEX_STATE, VuexState.INITIALIZED)
            }, 500)
        }

        // set top score player
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_TOP_SCORE_PARTICIPANT, data.top_score)
        // set my participation
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_MY_PARTICIPATION, data.my_participation)
        // set current participants
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_CURRENT_PARTICIPANTS, data.current_participants)
    }

    @Action
    public startDataPooling (): void {
        if (this.dataPoolingInterval !== null) return

        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, setInterval(async () => {
            if (this.tournamentData.vuexState === VuexState.INITIALIZED) {
                await this.refreshCurrentTournamentParticipants()
            }
        }, 60000))
    }

    @Action
    public stopDataPooling (): void {
        if (this.dataPoolingInterval != null) {
            clearInterval(this.dataPoolingInterval)
            this.context.commit(CurrentTournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, null)
        }
    }

    @Action
    public clearStore () {
        this.stopDataPooling()
        // set tournament
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT, null)
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_VUEX_STATE, VuexState.DATA_NOT_FOUND)

        // set leaderboard
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD, [])
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_PAGINATION, defaultVuexPaginatedData.pagination)
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_LEADERBOARD_VUEX_STATE, VuexState.DATA_NOT_FOUND)

        // set top score player
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_TOP_SCORE_PARTICIPANT, null)

        // set my participation
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_MY_PARTICIPATION, null)

        // set current participants
        this.context.commit(CurrentTournamentsModuleMutationTypes.SET_TOURNAMENT_CURRENT_PARTICIPANTS, 0)
    }
}

export const CurrentTournamentStoreModule = getModule(CurrentTournamentModule)