import {IPopupsData, PopupsQueueType} from "@/core/popups/types";

export interface IPopupsModuleState {
    popupsData: IPopupsData[]
    currentPopupsQueueType: PopupsQueueType
    currentPopupsQueueOrderIndex: number
}

export enum PopupsModuleGetterTypes {
    POPUPS_DATA = 'POPUPS_DATA'
}

export enum PopupsModuleMutationTypes {
    UPDATE_POPUPS_DATA = 'UPDATE_POPUPS_DATA',
    SET_POPUP_DATA = 'SET_POPUP_DATA',
    SET_CURRENT_POPUP_QUEUE_TYPE = 'SET_CURRENT_POPUP_QUEUE_TYPE',
    SET_CURRENT_POPUP_QUEUE_ORDER_INDEX = 'SET_CURRENT_POPUP_QUEUE_ORDER_INDEX',
}