export interface ICoreModuleState {
    isLoading: boolean
    isInitialized: boolean
}

export enum CoreModuleGetterTypes {
    IS_LOADING = 'IS_LOADING',
    IS_INITIALIZED = 'IS_INITIALIZED',
}

export enum CoreModuleMutationTypes {
    SET_LOADING = 'SET_LOADING',
    SET_INITIALIZED = 'SET_INITIALIZED',
}