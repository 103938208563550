import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const dashboardRoute: RouteConfig = {
    path: '/dashboard',
    name: RouteNames.DashboardRoute,
    component: () => import(/* webpackChunkName: "DashboardPage" */ '@/views/dashboard/DashboardPage.vue'),
    meta: {
        requiresAuth: AuthGuard.MUST_BE_AUTHENTICATED
    }
}