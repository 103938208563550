
























import {Component, Emit, Prop} from "vue-property-decorator";
import Vue from "vue";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {IAuthUser} from "@/core/auth/types";

@Component({})
export default class SuccessConnectedGameAccountPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: String, required: true, default: () => 'You are now connected' }) private readonly successMessage!: string

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  @Emit()
  private onCloseAction () {
    return
  }
}
