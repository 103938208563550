import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { auth } from '@/plugins/firebase'
import {IServiceError} from "@/core/service/types";
import {Result} from "typescript-result";
import {internalServerError} from "@/core/service/service.errors";

export class BaseAxiosService {
    public readonly baseURL: string
    public readonly client: AxiosInstance

    protected constructor (url: string) {
        this.baseURL = url
        this.client = axios.create({ baseURL: this.baseURL })
    }

    public async executeGet<M> (url: string, options: AxiosRequestConfig = {}): Promise<Result<IServiceError, M>> {
        try {
            const response = await this.client.get<M>(this.attachBaseURL(url), options)
            return Result.ok(response.data)
        } catch (e) {
            const error = e as AxiosError<IServiceError>
            if (error.response) {
                return Result.error(error.response.data)
            }

            return Result.error(internalServerError)
        }
    }

    public async executeWithPostContent<M> (url: string, content: object): Promise<Result<IServiceError, M>> {
        try {
            const response = await this.client.post<M>(this.attachBaseURL(url), content)
            return Result.ok(response.data)
        } catch (e) {
            const error = e as AxiosError<IServiceError>
            if (error.response) {
                return Result.error(error.response.data)
            }

            return Result.error(internalServerError)
        }
    }

    public async executeWithPatchContent<M> (url: string, content: object): Promise<Result<IServiceError, M>> {
        try {
            const response = await this.client.patch<M>(this.attachBaseURL(url), content)
            return Result.ok(response.data)
        } catch (e) {
            const error = e as AxiosError<IServiceError>
            if (error.response) {
                return Result.error(error.response.data)
            }

            return Result.error(internalServerError)
        }
    }

    public async executeWithPutContent<M> (url: string, content: object): Promise<Result<IServiceError, M>> {
        try {
            const response = await this.client.put<M>(this.attachBaseURL(url), content)
            return Result.ok(response.data)
        } catch (e) {
            const error = e as AxiosError<IServiceError>
            if (error.response) {
                return Result.error(error.response.data)
            }

            return Result.error(internalServerError)
        }
    }

    public async executeWithDeleteContent<M> (url: string, content: object = {}): Promise<Result<IServiceError, M>> {
        try {
            const response = await this.client.delete<M>(this.attachBaseURL(url), content)
            return Result.ok(response.data)
        } catch (e) {
            const error = e as AxiosError<IServiceError>
            if (error.response) {
                return Result.error(error.response.data)
            }

            return Result.error(internalServerError)
        }
    }

    public bindRequestInterceptor () {
        this.client.interceptors.request.use(async (config: AxiosRequestConfig) => {
            if (auth.currentUser) {
                const idToken = await auth.currentUser.getIdToken()
                config.headers.Authorization = [
                    'Bearer', idToken
                ].join(' ')
            } else {
                delete config.headers.Authorization
            }
            return config
        })
    }

    public bindResponseInterceptor () {

    }

    private attachBaseURL (url: string): string {
        return url.replace('{0}', this.baseURL)
    }
}
