























import Vue from "vue";
import {Component} from "vue-property-decorator";
import {GameType} from "@/core/games/types";
import ConnectGameAccountPopup, {IConnectGameAccountFormData} from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountPopup.vue";
import {PopupsStoreModule} from "@/store";
import SuccessConnectedGameAccountPopup from "@/components/GameAccounts/ConnectGameAccount/SuccessConnectedGameAccountPopup.vue";
import VerifyLeagueOfLegendsAccountPopup
  from "@/components/GameAccounts/ConnectGameAccount/leagueOfLegends/VerifyLeagueOfLegendsAccountPopup.vue";
import {LeagueOfLegendsRegions} from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import {ICreateLeagueOfLegendsGameAccountRequest} from "@/services/account/types";

export enum ConnectGameAccountPopupTypes {
  ConnectGameAccount,
  VerifyGameAccount,
  SuccessConnectedGameAccount
}

@Component({
  components: {
    SuccessConnectedGameAccountPopup,
    VerifyLeagueOfLegendsAccountPopup,
    ConnectGameAccountPopup
  },
  enums: {
    ConnectGameAccountPopupTypes
  }
})
export default class ConnectGameAccountContainer extends Vue {
  private gameType: GameType = GameType.None
  private formData: ICreateLeagueOfLegendsGameAccountRequest = {
    summonerName: '',
    regionCode: LeagueOfLegendsRegions.BR1
  }
  private state: ConnectGameAccountPopupTypes = ConnectGameAccountPopupTypes.ConnectGameAccount
  private successMessage: string = 'You are now connected'

  get connectGameAccountPopupIsActive (): boolean {
    return this.state === ConnectGameAccountPopupTypes.ConnectGameAccount
  }

  get verifyGameAccountPopupIsActive (): boolean {
    return this.state === ConnectGameAccountPopupTypes.VerifyGameAccount
  }

  get successConnectedGameAccountPopupIsActive (): boolean {
    return this.state === ConnectGameAccountPopupTypes.SuccessConnectedGameAccount
  }

  get verifyLeagueOfLegendsAccountPopupIsActive (): boolean {
    return this.gameType === GameType.LeagueOfLegends
  }

  private mounted () {
    this.state = ConnectGameAccountPopupTypes.ConnectGameAccount
  }

  private onGameAccountConnected (payload: IConnectGameAccountFormData) {
    this.gameType = payload.gameType
    this.formData = payload.formData
    this.state = ConnectGameAccountPopupTypes.VerifyGameAccount
  }

  private onSuccessVerifyAction (message: string) {
    this.successMessage = message
    this.state = ConnectGameAccountPopupTypes.SuccessConnectedGameAccount
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
