import store from "@/store/store";
import {StoreNamespace} from "@/store/types";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {AuthModuleGetterTypes, AuthModuleMutationTypes, IAuthModuleState} from "@/store/modules/auth/types";
import {auth, firebase} from "@/plugins/firebase";
import {RouteNames} from "@/router/types";
import router from "@/router";
import {CoreStoreModule} from "@/store";
import {IAuthUser} from "@/core/auth/types";
import {CoreModuleGetterTypes} from "@/store/modules/core/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Auth,
    store
})
class AuthModule extends VuexModule implements IAuthModuleState {
    user: IAuthUser | null = null
    isNewUser: boolean = false
    isAuthProcessStarted: boolean = false

    get [AuthModuleGetterTypes.IS_LOGGED_IN] () {
        return this.user !== null
    }

    get [AuthModuleGetterTypes.USER] () {
        return this.user
    }

    get [AuthModuleGetterTypes.IS_AUTH_PROCESS_STARTED] () {
        return this.isAuthProcessStarted
    }

    get [AuthModuleGetterTypes.IS_USER_LOGGED] () {
        return this.isNewUser
    }

    @Mutation
    [AuthModuleMutationTypes.SET_USER_INFO] (authUser: firebase.User | null) {
        if (authUser === null) {
            this.user = null
        } else {
            this.user = {
                id: authUser.uid,
                email: authUser.email,
                displayName: authUser.displayName,
                photoURL: authUser.photoURL
            }
        }
    }

    @Mutation
    [AuthModuleMutationTypes.SET_IS_AUTH_PROCESS_STARTED] (value: boolean) {
        this.isAuthProcessStarted = value
    }

    @Mutation
    [AuthModuleMutationTypes.SET_IS_NEW_USER] (value: boolean) {
        this.isNewUser = value
    }

    @Action
    public setupListeners(): void {
        auth.onAuthStateChanged(async (authUser: firebase.User | null) => {
            this.context.commit(AuthModuleMutationTypes.SET_USER_INFO, authUser)
            if (authUser !== null) {
                await CoreStoreModule.initializeAuthorizedStores()
                await CoreStoreModule.afterAuthentication()
            } else {
                await Promise.all([CoreStoreModule.clearAuthorizedStores()])
            }

            if (!CoreStoreModule[CoreModuleGetterTypes.IS_INITIALIZED]) {
                CoreStoreModule.setInitialized()
            }
        });
    }

    @Action
    public async signOut (): Promise<void> {
        await auth.signOut()
        this.context.commit(AuthModuleMutationTypes.SET_USER_INFO, null)
        this.context.commit(AuthModuleMutationTypes.SET_IS_AUTH_PROCESS_STARTED, false)
        this.context.commit(AuthModuleMutationTypes.SET_IS_NEW_USER, false)
        await router.replace({ name: RouteNames.AuthSignInRoute })
    }

    @Action
    public startAuthProcess (): void {
        this.context.commit(AuthModuleMutationTypes.SET_IS_AUTH_PROCESS_STARTED, true)
    }

    @Action
    public stopAuthProcess (): void {
        this.context.commit(AuthModuleMutationTypes.SET_IS_AUTH_PROCESS_STARTED, false)
    }

    @Action
    public setIsNewUser (isNewUser: boolean): void {
        this.context.commit(AuthModuleMutationTypes.SET_IS_NEW_USER, isNewUser)
    }

    @Action
    public signInWithProvider (customToken: string): Promise<firebase.auth.UserCredential> {
        return auth.signInWithCustomToken(customToken)
    }

    @Action
    public async refreshAuthUser (): Promise<void> {
        if (auth.currentUser === null) return

        try {
            await auth.currentUser.reload()
            this.context.commit(AuthModuleMutationTypes.SET_USER_INFO, auth.currentUser)
        } catch (e) {
            console.error('Can`t reload current auth user data')
        }
    }

    @Action
    public clearStore (): void {

    }
}

export const AuthStoreModule = getModule(AuthModule)