import store from "@/store/store";
import {StoreNamespace} from "@/store/types";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {AccountModuleGetterTypes, AccountModuleMutationTypes, IAccountModuleState} from "@/store/modules/account/types";
import {accountService} from "@/services";
import {Result} from "typescript-result";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {
    IUpdateAccountDisplayNameRequest,
    IUpdateAccountEmailRequest,
    IUpdateAccountPasswordRequest
} from "@/services/account/types";
import {AuthStoreModule} from "@/store";
import {IServiceError} from "@/core/service/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Account,
    store
})
class AccountModule extends VuexModule implements IAccountModuleState {
    account: IAccountEntity | null = null

    get [AccountModuleGetterTypes.ACCOUNT] () {
        return this.account
    }

    @Mutation
    [AccountModuleMutationTypes.SET_ACCOUNT] (account: IAccountEntity | null) {
        if (account === null) {
            this.account = null
        } else {
            this.account = account
        }
    }

    @Action
    public async initialize () {
        await this.getAccount()
    }

    @Action
    public async getAccount(): Promise<Result<null, IAccountEntity>> {
        const getAccountResult = await accountService.getAccount()
        if (getAccountResult.isFailure()) {
            return Result.error(null)
        }

        this.context.commit(AccountModuleMutationTypes.SET_ACCOUNT, getAccountResult.value)
        return Result.ok(getAccountResult.value)
    }

    @Action
    public async refreshRiotVerificationCode(): Promise<Result<null, IAccountEntity>> {
        const refreshRiotVerificationCodeResult = await accountService.refreshRiotVerificationCode()
        if (refreshRiotVerificationCodeResult.isFailure()) {
            return Result.error(null)
        }

        this.context.commit(AccountModuleMutationTypes.SET_ACCOUNT, refreshRiotVerificationCodeResult.value)
        return Result.ok(refreshRiotVerificationCodeResult.value)
    }

    @Action
    public async updateDisplayName(content: IUpdateAccountDisplayNameRequest): Promise<Result<IServiceError, IAccountEntity>> {
        const updateAccountDisplayNameResult = await accountService.updateAccountDisplayName(content)
        if (updateAccountDisplayNameResult.isFailure()) {
            return Result.error(updateAccountDisplayNameResult.error)
        }
        await AuthStoreModule.refreshAuthUser()
        this.context.commit(AccountModuleMutationTypes.SET_ACCOUNT, updateAccountDisplayNameResult.value)
        return Result.ok(updateAccountDisplayNameResult.value)
    }

    @Action
    public async updateEmail(content: IUpdateAccountEmailRequest): Promise<Result<IServiceError, IAccountEntity>> {
        const updateAccountEmailResult = await accountService.updateAccountEmail(content)
        if (updateAccountEmailResult.isFailure()) {
            return Result.error(updateAccountEmailResult.error)
        }
        this.context.commit(AccountModuleMutationTypes.SET_ACCOUNT, updateAccountEmailResult.value)
        return Result.ok(updateAccountEmailResult.value)
    }

    @Action
    public async updatePassword(content: IUpdateAccountPasswordRequest): Promise<Result<IServiceError, IAccountEntity>> {
        const updateAccountPasswordResult = await accountService.updateAccountPassword(content)
        if (updateAccountPasswordResult.isFailure()) {
            return Result.error(updateAccountPasswordResult.error)
        }
        this.context.commit(AccountModuleMutationTypes.SET_ACCOUNT, updateAccountPasswordResult.value)
        return Result.ok(updateAccountPasswordResult.value)
    }

    @Action
    public clearStore () {
        this.context.commit(AccountModuleMutationTypes.SET_ACCOUNT, null)
    }
}

export const AccountStoreModule = getModule(AccountModule)