import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const friendInvitationRoute: RouteConfig = {
    path: '/friends/invite',
    name: RouteNames.FriendInvitationRoute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FriendInvitationPage" */ '@/views/friends/FriendInvitationPage.vue'),
    meta: {
        requiresAuth: AuthGuard.MUST_BE_AUTHENTICATED
    }
}