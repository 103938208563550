import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
      mobileBreakpoint: 1920
    },
    rtl: false,
    theme: {
      dark: true,
      default: 'light',
      disable: false,
      options: {
        cspNonce: undefined,
        customProperties: true,
        minifyTheme: undefined,
        themeCache: undefined,
      },
      themes: {
        light: {
          primary: '#1976D2',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
        dark: {
          custom_dark: colors.grey.darken4,
          custom_dark_lighten: '#6d6d6d',
          custom_dark_darken: '#1b1b1b',
          secondary: colors.blue.darken2,
          secondary_light: '#63a4ff',
          secondary_dark: '#004ba0',
          accent: colors.grey,
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00'
        },
      },
    },
  });
