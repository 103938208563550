import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import {IPaginationResult} from "@/core/service/types";

export enum TournamentsServiceUrlPaths {
    GetTournamentsURL = '/',
    GetTournamentByIdURL = '/{tournamentId}',
    GetTournamentParticipantsURL = '/{tournamentId}/participants'
}

export interface ITournamentResponse {
    tournament: ITournamentEntity
    data: ITournamentParticipantsResponse
}

export interface ITournamentParticipantsResponse {
    leaderboard?: IPaginationResult<ITournamentParticipantEntity[]>
    top_score?: ITournamentParticipantEntity | null
    my_participation: ITournamentParticipantEntity | null
    current_participants: number
}