import {RouteNames} from "@/router/types";
import {Location} from "vue-router";
import {AuthStoreModule} from "@/store";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";

export class DefaultRedirectRoutes {
    public static defaultAuthRouteName(location?: Location): Location {
        const locationObject = { name: RouteNames.AuthSignInRoute }
        if (location) {
            return {...locationObject, ...location }
        }
        return locationObject
    }

    public static defaultMainRouteName(location?: Location): Location {
        if (AuthStoreModule[AuthModuleGetterTypes.IS_LOGGED_IN]) {
            const locationObject = {
                name: RouteNames.UserProfileRoute,
                params: { userId: AuthStoreModule[AuthModuleGetterTypes.USER]!.id }
            }
            if (location) {
                return {...locationObject, ...location }
            }

            return locationObject
        } else {
            const locationObject = { name: RouteNames.HomeRoute }
            if (location) {
                return {...locationObject, ...location }
            }

            return locationObject
        }
    }
}