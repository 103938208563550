









import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";

@Component({})
export default class AddFriendSmallButton extends Vue {
  private onAddFriend () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.CopyFriendInvitationLink
    })
  }
}
