import {IPopupsData, PopupsQueueType, PopupType} from "@/core/popups/types";
import {filter, find, sortBy} from "lodash";
import {AccountFriendsModuleStoreModule, AccountStoreModule, AuthStoreModule} from "@/store";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import {Result} from "typescript-result";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";

export const getPopupData = (popupsData: IPopupsData[], popupType: PopupType): IPopupsData | null => {
    const popupData = find(popupsData, (popupData) => popupData.type === popupType)
    if (popupData) {
        return popupData
    }

    return null
}

export const validatePopupState = (popupType: PopupType) => {
    switch (popupType) {
        case PopupType.Welcome:
            return !AccountFriendsModuleStoreModule[AccountGameAccountsModuleGetterTypes.FIRST_GAME_ACCOUNT_IS_CONNECTED]
                && AuthStoreModule[AuthModuleGetterTypes.IS_USER_LOGGED];
        case PopupType.NewFriendInfo:
            return AccountFriendsModuleStoreModule[AccountFriendsModuleGetterTypes.INVITED_BY_ACCOUNT] !== null;
        default:
            return true
    }
}

export const getNextPopupInQueue = (popupsData: IPopupsData[], popupsQueueType: PopupsQueueType, currentPopupsQueueOrderIndex: number): Result<null, IPopupsData> => {
    const nextOrderedPopup = find(sortBy(popupsData, `order`), (popupData) =>
        popupData.queue === popupsQueueType && popupData.order === currentPopupsQueueOrderIndex + 1 && validatePopupState(popupData.type))

    if (nextOrderedPopup) {
        return Result.ok(nextOrderedPopup)
    }

    return Result.error(null)
}

export const getCurrentActivePopup = (popupsData: IPopupsData[]): Result<null, IPopupsData> => {
    const currentOrderedPopups = find(popupsData, (popupData) => popupData.isActive === true && popupData.isInstantiated === true)

    if (currentOrderedPopups) {
        return Result.ok(currentOrderedPopups)
    }

    return Result.error(null)
}

export const getPopupByType = (popupsData: IPopupsData[], popupType: PopupType): Result<null, IPopupsData> => {
    const currentOrderedPopups = find(popupsData, (popupData) => popupData.type === popupType)

    if (currentOrderedPopups) {
        return Result.ok(currentOrderedPopups)
    }

    return Result.error(null)
}

export const getNearestPopupInQueue = (popupsData: IPopupsData[], popupsQueueType: PopupsQueueType, lastPopupsQueueOrderIndex: number): Result<null, IPopupsData> => {
    const nearestPopupsInQueue = sortBy(filter(popupsData, (popupData) =>
        popupData.queue === popupsQueueType && popupData.order > lastPopupsQueueOrderIndex && validatePopupState(popupData.type)), 'order')

    if (nearestPopupsInQueue.length > 0) {
        return Result.ok(nearestPopupsInQueue[nearestPopupsInQueue.length - 1])
    }

    return Result.error(null)
}