import {IPopupsData, PopupsQueueType, PopupType} from "@/core/popups/types";

const popupsStoreConfig: IPopupsData[] = [
    {
        type: PopupType.Welcome,
        queue: PopupsQueueType.AfterAuthentication,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.ConnectGameAccount,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.DisconnectGameAccount,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.AddFriend,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.CopyFriendInvitationLink,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.NewFriendInfo,
        queue: PopupsQueueType.AfterAuthentication,
        order: 1,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.DeleteFriend,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.TournamentJoin,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.TournamentSendAddRequest,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.TournamentRemoveParticipant,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.TournamentAcceptAddRequest,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.TournamentUpdateParticipant,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.TournamentCancel,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.UpdateAccountDisplayNamePopup,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.UpdateAccountEmailPopup,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    },
    {
        type: PopupType.UpdateAccountPasswordPopup,
        queue: PopupsQueueType.None,
        order: 0,
        isActive: false,
        isInstantiated: false,
        data: {}
    }
]

export {
    popupsStoreConfig
}