import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {IVuexData, IVuexPaginatedData} from "@/core/vuex/types";
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import {Result} from "typescript-result";
import {IPaginationParams} from "@/core/service/types";

export interface ICurrentTournamentModuleState {
    tournamentData: IVuexData<ITournamentEntity>
    tournamentLeaderboardData: IVuexPaginatedData<ITournamentParticipantEntity[]>
    tournamentTopScoreParticipant: ITournamentParticipantEntity | null
    tournamentMyParticipation: ITournamentParticipantEntity | null
    tournamentCurrentParticipants: number
    dataPoolingInterval: NodeJS.Timeout | null

    initialize (tournamentId: string): Promise<void>
    setTournament(tournamentId: number): Promise<Result<null, ITournamentEntity>>
    setTournamentParticipants(data: ISetTournamentData): Promise<Result<null, ITournamentParticipantEntity[]>>
    refreshCurrentTournamentParticipants(): Promise<Result<null, ITournamentParticipantEntity[]>>
    onCurrentPaginationPageChange(newCurrentPage: number)
    startDataPooling (): void
    stopDataPooling (): void
    clearStore (): void
}

export enum CurrentTournamentModuleGetterTypes {
    TOURNAMENT = 'TOURNAMENT',
    TOURNAMENT_VUEX_STATE = 'TOURNAMENT_VUEX_STATE',
    TOURNAMENT_LEADERBOARD_PAGINATION = 'TOURNAMENT_LEADERBOARD_PAGINATION',
    TOURNAMENT_LEADERBOARD_VUEX_STATE = 'TOURNAMENT_LEADERBOARD_VUEX_STATE',
}

export enum CurrentTournamentsModuleMutationTypes {
    SET_TOURNAMENT = 'SET_TOURNAMENT',
    SET_TOURNAMENT_VUEX_STATE = 'SET_TOURNAMENT_VUEX_STATE',
    SET_TOURNAMENT_LEADERBOARD = 'SET_TOURNAMENT_LEADERBOARD',
    SET_TOURNAMENT_LEADERBOARD_PAGINATION = 'SET_TOURNAMENT_LEADERBOARD_PAGINATION',
    SET_TOURNAMENT_LEADERBOARD_VUEX_STATE = 'SET_TOURNAMENT_LEADERBOARD_VUEX_STATE',
    SET_TOURNAMENT_TOP_SCORE_PARTICIPANT = 'SET_TOURNAMENT_TOP_SCORE_PARTICIPANT',
    SET_TOURNAMENT_MY_PARTICIPATION = 'SET_TOURNAMENT_MY_PARTICIPATION',
    SET_TOURNAMENT_CURRENT_PARTICIPANTS = 'SET_TOURNAMENT_CURRENT_PARTICIPANTS',
    SET_DATA_POOLING_INTERVAL = 'SET_DATA_POOLING_INTERVAL'
}

export interface ISetTournamentData {
    tournamentId: number
    pagination?: IPaginationParams
}