import moment, { Moment } from 'moment'

export class Timing {
    public static getTimeNow (): Moment {
        return moment().utc()
    }

    public static getTimeDiff (startTime: Moment, endTime: Moment): number {
        return endTime.diff(startTime)
    }

    public static formatTime = (time: number, format: string = 'mm:ss') => {
        return moment(time).utc().format(format)
    }

    public static localTimePmStr = (time: number, format: string = 'mm:ss') => {
        const m = moment(time).local();
        const hours = m.hours() < 10 ? `0${m.hours()}` : m.hours();
        const mins = m.minutes() < 10 ? `0${m.minutes()}` : m.minutes();
        const seconds = m.seconds() < 10 ? `0${m.seconds()}` : m.seconds();

        return `${m.format('DD MMM')} ${hours}:${mins}:${seconds}`;
    }

    public static formatMoment = (time: Moment, format: string = 'mm:ss') => {
        return time.local().format(format)
    }

    public static UnixToMoment (timestamp: number): Moment {
        return moment(timestamp * 1000).utc()
    }

    public static StringToMoment (dateTime: string): Moment {
        return moment(dateTime).utc()
    }

    public static formatDuration = (time: number, format: string = 'hh:mm:ss') => {
        return (moment.duration(time, 'milliseconds') as any).format(format, {
            trim: false
        })
    }
}