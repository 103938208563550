import { RouteConfig } from 'vue-router'
import {AuthGuard, RouteNames} from "@/router/types";

export const authSignUpRoute: RouteConfig = {
    path: '/auth/signUp',
    name: RouteNames.AuthSignUpRoute,
    component: () => import(/* webpackChunkName: "SignUpPage" */ '@/views/auth/SignUpPage.vue'),
    meta: {
        requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
    }
}
