import {BaseAxiosService} from "@/core/service/base.service";
import {IPaginationParams, IPaginationResult, IServiceError} from "@/core/service/types";
import {
    AccountServiceUrlPaths,
    IAddFriendRequest,
    ICreateLeagueOfLegendsGameAccountRequest,
    IUpdateAccountDisplayNameRequest,
    IUpdateAccountEmailRequest,
    IUpdateAccountPasswordRequest
} from "@/services/account/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {Result} from "typescript-result";
import {
    ITournamentAcceptAddRequest, ITournamentCancelResponse,
    ICreateTournamentRequest,
    ITournamentJoinRequest, ITournamentUpdateParticipantRequest, ITournamentDeleteParticipantRequest
} from "@/services/account/tournament.types";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import {ITournamentResponse} from "@/services/tournaments/types";

export class AccountService extends BaseAxiosService {
    constructor() {
        super(process.env.VUE_APP_ACCOUNT_SERVICE_URL as string)
        this.bindRequestInterceptor()
        this.bindResponseInterceptor()
    }

    public static create () {
        return new AccountService()
    }

    // Account - start
    public getAccount (): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeGet<IAccountEntity>(
            AccountServiceUrlPaths.GetAccountURL
        )
    }

    public refreshRiotVerificationCode (): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeWithPatchContent<IAccountEntity>(
            AccountServiceUrlPaths.PatchRiotVerificationCodeURL, {}
        )
    }

    public updateAccountEmail (content: IUpdateAccountEmailRequest): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeWithPatchContent<IAccountEntity>(
            AccountServiceUrlPaths.PatchAccountEmailURL, content
        )
    }

    public updateAccountPassword (content: IUpdateAccountPasswordRequest): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeWithPatchContent<IAccountEntity>(
            AccountServiceUrlPaths.PatchAccountPasswordURL, content
        )
    }

    public updateAccountDisplayName (content: IUpdateAccountDisplayNameRequest): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeWithPatchContent<IAccountEntity>(
            AccountServiceUrlPaths.PatchAccountDisplayNameURL, content
        )
    }
    // Account - end

    // GameAccounts - start
    public getGameAccounts (): Promise<Result<IServiceError, IGameAccountEntity[]>> {
        return this.executeGet<IGameAccountEntity[]>(
            AccountServiceUrlPaths.GetGameAccountsURL
        )
    }

    public getGameAccountById (gameAccountId: number): Promise<Result<IServiceError, IGameAccountEntity>> {
        return this.executeGet<IGameAccountEntity>(
            AccountServiceUrlPaths.GetGameAccountByIdURL
                .replace('{gameAccountId}', gameAccountId.toString())
        )
    }

    public createLeagueOfLegendsGameAccount (content: ICreateLeagueOfLegendsGameAccountRequest): Promise<Result<IServiceError, IGameAccountEntity>> {
        return this.executeWithPostContent<IGameAccountEntity>(
            AccountServiceUrlPaths.CreateLeagueOfLegendsGameAccountURL, content
        )
    }

    public deleteGameAccountById (gameAccountId: number): Promise<Result<IServiceError, void>> {
        return this.executeWithDeleteContent<void>(
            AccountServiceUrlPaths.DeleteGameAccountByIdURL
                .replace('{gameAccountId}', gameAccountId.toString())
        )
    }
    // GameAccounts - end

    // Friends - start
    public getFriends (): Promise<Result<IServiceError, IAccountEntity[]>> {
        return this.executeGet<IAccountEntity[]>(
            AccountServiceUrlPaths.GetFriendsURL
        )
    }

    public getFriendById (gameAccountId: string): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeGet<IAccountEntity>(
            AccountServiceUrlPaths.GetFriendByIdURL
                .replace('{friendId}', gameAccountId)
        )
    }

    public addFriend (content: IAddFriendRequest): Promise<Result<IServiceError, IAccountEntity>> {
        return this.executeWithPostContent<IAccountEntity>(
            AccountServiceUrlPaths.AddFriendURL, content
        )
    }

    public deleteFriendById (friendId: string): Promise<Result<IServiceError, void>> {
        return this.executeWithDeleteContent<void>(
            AccountServiceUrlPaths.DeleteFriendByIdURL
                .replace('{friendId}', friendId)
        )
    }
    // Friends - end

    // Tournaments - start
    public getParticipatedTournaments (pagination?: IPaginationParams): Promise<Result<IServiceError, IPaginationResult<ITournamentResponse[]>>> {
        return this.executeGet<IPaginationResult<ITournamentResponse[]>>(
            AccountServiceUrlPaths.GetParticipatedTournaments, { params: pagination ? pagination : {} }
        )
    }
    public getOwnedTournaments (pagination?: IPaginationParams): Promise<Result<IServiceError, IPaginationResult<ITournamentResponse[]>>> {
        return this.executeGet<IPaginationResult<ITournamentResponse[]>>(
            AccountServiceUrlPaths.GetOwnedTournaments, { params: pagination ? pagination : {} }
        )
    }
    public getCompletedTournaments (pagination?: IPaginationParams): Promise<Result<IServiceError, IPaginationResult<ITournamentResponse[]>>> {
        return this.executeGet<IPaginationResult<ITournamentResponse[]>>(
            AccountServiceUrlPaths.GetCompletedTournaments, { params: pagination ? pagination : {} }
        )
    }
    public createTournament (content: ICreateTournamentRequest): Promise<Result<IServiceError, ITournamentEntity>> {
        return this.executeWithPostContent<ITournamentEntity>(
            AccountServiceUrlPaths.CreateTournament, content
        )
    }
    public cancelTournament (tournamentId: number): Promise<Result<IServiceError, ITournamentCancelResponse>> {
        return this.executeWithPatchContent<ITournamentCancelResponse>(
            AccountServiceUrlPaths.CancelTournament.replace('{tournamentId}', tournamentId.toString()), {}
        )
    }
    public joinParticipantToTournament (tournamentId: number, content: ITournamentJoinRequest): Promise<Result<IServiceError, ITournamentParticipantEntity>> {
        return this.executeWithPostContent<ITournamentParticipantEntity>(
            AccountServiceUrlPaths.JoinToTournament.replace('{tournamentId}', tournamentId.toString()), content
        )
    }
    public updateTournamentParticipant (tournamentId: number, participantId: number, content: ITournamentUpdateParticipantRequest): Promise<Result<IServiceError, ITournamentParticipantEntity>> {
        return this.executeWithPatchContent<ITournamentParticipantEntity>(
            AccountServiceUrlPaths.UpdateTournamentParticipant
                .replace('{tournamentId}', tournamentId.toString())
                .replace('{participantId}', participantId.toString()), content
        )
    }
    public acceptTournamentParticipantInviteRequest (tournamentId: number, participantId: number, content: ITournamentAcceptAddRequest): Promise<Result<IServiceError, ITournamentParticipantEntity>> {
        return this.executeWithPatchContent<ITournamentParticipantEntity>(
            AccountServiceUrlPaths.AcceptTournamentParticipantInviteRequest
                .replace('{tournamentId}', tournamentId.toString())
                .replace('{participantId}', participantId.toString()), content
        )
    }
    public deleteParticipantFromTournament (tournamentId: number, participantId: number, content: ITournamentDeleteParticipantRequest): Promise<Result<IServiceError, any>> {
        return this.executeWithDeleteContent<any>(
            AccountServiceUrlPaths.DeleteParticipantFromTournament
                .replace('{tournamentId}', tournamentId.toString())
                .replace('{participantId}', participantId.toString()), content
        )
    }
    // Tournaments - end
}