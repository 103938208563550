import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {StoreNamespace} from "@/store/types";
import store from "@/store/store";
import {IAccountGameAccountsModuleState, AccountGameAccountsModuleGetterTypes, AccountGameAccountsModuleMutationTypes} from "@/store/modules/account/gameAccounts/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {Result} from "typescript-result";
import {accountService} from "@/services";
import {ICreateLeagueOfLegendsGameAccountRequest} from "@/services/account/types";
import {IServiceError} from "@/core/service/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.AccountGameAccounts,
    store
})
class AccountGameAccountsModule extends VuexModule implements IAccountGameAccountsModuleState {
    gameAccounts: IGameAccountEntity[] = []

    get [AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS] () {
        return this.gameAccounts
    }

    get [AccountGameAccountsModuleGetterTypes.FIRST_GAME_ACCOUNT_IS_CONNECTED] () {
        return this.gameAccounts.length > 0
    }

    @Mutation
    [AccountGameAccountsModuleMutationTypes.SET_GAME_ACCOUNTS] (gameAccounts: IGameAccountEntity[]) {
        this.gameAccounts = gameAccounts
    }

    @Mutation
    [AccountGameAccountsModuleMutationTypes.UPDATE_GAME_ACCOUNT] (gameAccount: IGameAccountEntity) {
        const index = this.gameAccounts.findIndex((currentGameAccount) => currentGameAccount.id === gameAccount.id)
        if (index !== -1) {
            this.gameAccounts.splice(index, 1, gameAccount)
        } else {
            this.gameAccounts.push(gameAccount)
        }
    }

    @Mutation
    [AccountGameAccountsModuleMutationTypes.DELETE_GAME_ACCOUNT] (gameAccountId: number) {
        const index = this.gameAccounts.findIndex((currentGameAccount) => currentGameAccount.id === gameAccountId)
        if (index !== -1) {
            this.gameAccounts.splice(index, 1)
        }
    }

    @Action
    public async initialize () {
        await this.getGameAccounts()
    }

    @Action
    public async getGameAccounts(): Promise<Result<[], IGameAccountEntity[]>> {
        const getGameAccountsResult = await accountService.getGameAccounts()

        if (getGameAccountsResult.isFailure()) {
            return Result.error([])
        }

        this.context.commit(AccountGameAccountsModuleMutationTypes.SET_GAME_ACCOUNTS, getGameAccountsResult.value)
        return Result.ok(getGameAccountsResult.value)
    }

    @Action
    public async createLeagueOfLegendsGameAccount(content: ICreateLeagueOfLegendsGameAccountRequest): Promise<Result<IServiceError, IGameAccountEntity>> {
        const createLeagueOfLegendsGameAccountResult = await accountService.createLeagueOfLegendsGameAccount(content)

        if (createLeagueOfLegendsGameAccountResult.isFailure()) {
            return Result.error(createLeagueOfLegendsGameAccountResult.error)
        }

        this.context.commit(AccountGameAccountsModuleMutationTypes.UPDATE_GAME_ACCOUNT, createLeagueOfLegendsGameAccountResult.value)
        return Result.ok(createLeagueOfLegendsGameAccountResult.value)
    }

    @Action
    public async deleteGameAccount(gameAccountId: number): Promise<Result<IServiceError, void>> {
        const deleteGameAccountResult = await accountService.deleteGameAccountById(gameAccountId)

        if (deleteGameAccountResult.isFailure()) {
            return Result.error(deleteGameAccountResult.error)
        }

        this.context.commit(AccountGameAccountsModuleMutationTypes.DELETE_GAME_ACCOUNT, gameAccountId)
        return Result.ok()
    }

    @Action
    public clearStore () {
        this.context.commit(AccountGameAccountsModuleMutationTypes.SET_GAME_ACCOUNTS, [])
    }
}

export const AccountGameAccountsModuleStoreModule = getModule(AccountGameAccountsModule)