import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const userProfilePage: RouteConfig = {
    path: '/users/:userId',
    name: RouteNames.UserProfileRoute,
    component: () => import(/* webpackChunkName: "UserProfilePage" */ '@/views/UserProfilePage.vue'),
    meta: {
        requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
    }
}