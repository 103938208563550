import {LeagueOfLegendsRegions} from "@/core/games/leagueOfLegends/leagueOfLegends.types";

export enum AccountServiceUrlPaths {
    // Account - start
    GetAccountURL = '/',
    PatchRiotVerificationCodeURL = '/refresh-riot-verification-code',
    PatchAccountEmailURL = 'email',
    PatchAccountPasswordURL = 'password',
    PatchAccountDisplayNameURL = 'display-name',
    // Account - end

    // GameAccounts - start
    GetGameAccountsURL = '/game-accounts',
    GetGameAccountByIdURL = '/game-accounts/{gameAccountId}',
    CreateLeagueOfLegendsGameAccountURL = '/game-accounts/league-of-legends',
    DeleteGameAccountByIdURL = '/game-accounts/{gameAccountId}',
    // GameAccounts - end

    // Friends - start
    GetFriendsURL = '/friends',
    GetFriendByIdURL = '/friends/{friendId}',
    AddFriendURL = '/friends',
    DeleteFriendByIdURL = '/friends/{friendId}',
    // Friends - end

    // Tournaments - start
    GetParticipatedTournaments = '/tournaments',
    GetOwnedTournaments = '/tournaments/owned',
    GetCompletedTournaments = '/tournaments/completed',
    CreateTournament = '/tournaments',
    CancelTournament = '/tournaments/{tournamentId}/cancel',
    JoinToTournament = '/tournaments/{tournamentId}/join',
    UpdateTournamentParticipant = '/tournaments/{tournamentId}/participants/{participantId}',
    AcceptTournamentParticipantInviteRequest = '/tournaments/{tournamentId}/participants/{participantId}/accept',
    DeleteParticipantFromTournament = '/tournaments/{tournamentId}/participants/{participantId}'
    // Tournaments - end
}

export interface ICreateLeagueOfLegendsGameAccountRequest {
    summonerName: string
    regionCode: LeagueOfLegendsRegions
}

export interface IUpdateAccountEmailRequest {
    email: string | undefined
}

export interface IUpdateAccountPasswordRequest {
    password: string | undefined
    password_confirmation: string | undefined
}

export interface IUpdateAccountDisplayNameRequest {
    display_name: string | undefined
}

export interface IAddFriendRequest {
    invitation_code: string
}
