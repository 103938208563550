import { IUniqueChampionData } from "@/components/stats/lol/ScoreTracer/UniqueChamps/types";
import { ICharacteristics, ILolMatch, IScore, ISummoner } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { IItemData, IQueueData, IRuneData, ISummonerSPellsData } from "@/services/RiotAssets/types";

export interface IMostFrequentChampion {
    count: number,
    champion: {
        name: string,
        id: number
    }
}

export enum LOADING_STATUS {
    READY,
    LOADING,
    FAILED
}

export interface ChampsData {
    best: IUniqueChampionData,
    worst: IUniqueChampionData
}

export interface LolModuleState {
    summoner: ISummoner | null,
    matches: Array<ILolMatch>,
    characteristic: ICharacteristics | undefined,
    championsData: ChampsData | undefined,
    score: IScore | undefined,
    mostFrequentChampion: IMostFrequentChampion | undefined,
    status: LOADING_STATUS,
    message: {statusCode?: number, message?: string} | undefined,
    lastSearchedRegion: string,
    NOfMatchesfirstFetch: number,
    queueListData: IQueueData[] | undefined,
    summonerSpells: ISummonerSPellsData | undefined,
    runesData: IRuneData[] | undefined,
    itemsData: Array<[string, IItemData]> | undefined,
    backgroundSkinId: number
}

export enum LolModuleGetterTypes {
    BACKGROUND_SKIN_ID = "BACKGROUND_SKIN_ID",
    LAST_SEARCHED_REGION = "LAST_SEARCHED_REGION",
    IS_LOADING = "IS_LOADING",
    IS_READY = "IS_READY",
    SUMMONER_ERROR = "SUMMONER_ERROR",
    SUMMONER_FOUND = "SUMMONER_FOUND",
    EMPTY_SUMMONER = "EMPTY_SUMMONER",
    SUMMONER = "SUMMONER",
    PROFILE_ICON = "PROFILE_ICON",
    MATCHES = "MATCHES",
    CHARACTERISTIC = "CHARACTERISTIC",
    LAST_PLAYED_CHAMPION_NAME = "LAST_PLAYED_CHAMPION_NAME",
    SUMMONER_RANK_SOLO = "SUMMONER_RANK_SOLO",
    SUMMONER_RANK_FLEX = "SUMMONER_RANK_FLEX",
    PLAYER_SCORE = "PLAYER_SCORE",
    UPDATE_TIME = "UPDATE_TIME",
    QUEUE_DATA = "QUEUE_DATA",
    RUNES_DATA = "RUNES_DATA",
    ITEMS_DATA = "ITEMS_DATA",
    SUMMONER_SPELLS = "SUMMONER_SPELLS",
    MATCHES_FIRST_FETCH_N = "MATCHES_FIRST_FETCH_N",
    CHAMPIONS_DATA = 'CHAMPIONS_DATA',
    MESSAGE = 'MESSAGE'
}

export enum LolModuleMutationTypes {
    SET_BACKGROUND_SKIN_ID = "SET_BACKGROUND_SKIN_ID",
    SET_SUMMONER = "SET_SUMMONER",
    SET_MATCHES = "SET_MATCHES",
    CALCULATE_LAST_PLAYED_CHAMP = "CALCULATE_LAST_PLAYED_CHAMP",
    SET_STATUS = "SET_STATUS",
    SET_MESSAGE = "SET_MESSAGE",
    SET_CHARACTERISTIC = "SET_CHARACTERISTIC",
    SET_SCORE = "SET_SCORE",
    SET_QUEUE_LIST_DATA = "SET_QUEUE_LIST_DATA",
    SET_SUMMONER_SPELLS_DATA = "SET_SUMMONER_SPELLS_DATA",
    SET_RUNES_DATA = "SET_RUNES_DATA",
    SET_ITEMS_DATA = "SET_ITEMS_DATA",
    SET_LAST_REGION = "SET_LAST_REGION",
    SET_CHAMPIONS_DATA = "SET_CHAMPIONS_DATA"
}