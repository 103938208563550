import { IChampionsData, ICharacteristics, ILolMatch, IScore, ISummoner } from "@/core/games/leagueOfLegends/leagueOfLegends.types"

export interface IWarningResult {
    message: string,
    extras?: string[];
}

export enum LolServiceUrlPaths {
    
    GetMatchesURL =  '/matches/{summonerName}/{regionCode}',
    GetSummonerURL = '/summoner/{summonerName}/{regionCode}/{matchesCount}/{forced}',
    PatchRiotApiKeyURL = '/riot_api_key/{riotApiKey}'

}

export interface ISummonerGetResponse {
    champions_data: IChampionsData,
    characteristics: ICharacteristics,
    matches: Array<ILolMatch>,
    score: IScore,
    summoner: ISummoner,
    warnings: IWarningResult[]
}
