import Vue from 'vue'

export class EventBus extends Vue {
    public AddListener (event: string, callback: Function) {
        eventBus.$on(event, callback)
    }

    public RemoveListener (event: string, callback: Function) {
        eventBus.$off(event, callback)
    }
}

const eventBus = new EventBus()
Vue.prototype.$eventBus = eventBus

export {
    eventBus
}
