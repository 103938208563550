import {IVuexData, IVuexPaginatedData} from "@/core/vuex/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {ITournamentResponse} from "@/services/tournaments/types";
import {Result} from "typescript-result";

export interface IUserProfileModuleState {
    accountData: IVuexData<IAccountEntity | null>
    tournamentsData: IVuexPaginatedData<ITournamentResponse[]>

    initialize (userId: string): Promise<Result<null, IAccountEntity>>
    initializeWithCurrentAccount (): Promise<Result<null, IAccountEntity>>
    clearStore ()
}

export enum UserProfileModuleGetterTypes {
    ACCOUNT = 'ACCOUNT',
    ACCOUNT_VUEX_STATE = 'ACCOUNT_VUEX_STATE',
    ACCOUNT_TOURNAMENTS = 'ACCOUNT_TOURNAMENTS',
    ACCOUNT_TOURNAMENTS_PAGINATION = 'ACCOUNT_TOURNAMENTS_PAGINATION',
    ACCOUNT_TOURNAMENTS_VUEX_STATE = 'ACCOUNT_TOURNAMENTS_VUEX_STATE',
}

export enum UserProfileModuleMutationTypes {
    SET_ACCOUNT = 'SET_ACCOUNT',
    SET_ACCOUNT_VUEX_STATE = 'SET_ACCOUNT_VUEX_STATE',
    SET_ACCOUNT_TOURNAMENTS = 'SET_ACCOUNT_TOURNAMENTS',
    SET_ACCOUNT_TOURNAMENTS_PAGINATION = 'SET_ACCOUNT_TOURNAMENTS_PAGINATION',
    SET_ACCOUNT_TOURNAMENTS_VUEX_STATE = 'SET_ACCOUNT_TOURNAMENTS_VUEX_STATE',
}