var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.isActive,"persistent":"","max-width":"400px"}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_c('h4',[_vm._v("Update account password")])])],1),_c('v-card',{staticClass:"pa-3",attrs:{"tile":""}},[_c('validation-observer',{ref:"updatePasswordValidationObserver",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onUpdatePasswordAction)}}},[_c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Password","disabled":_vm.isProcessing,"outlined":"","dense":"","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Password confirmation","disabled":_vm.isProcessing,"outlined":"","dense":"","required":""},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"w-100 d-flex flex-column"},[_c('v-btn',{staticClass:"mb-3 white--text",attrs:{"color":"purple","type":"submit","rounded":"","block":"","disabled":_vm.isProcessing || invalid,"loading":_vm.isProcessing}},[_vm._v(" Update ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"purple","text":"","rounded":"","block":"","disabled":_vm.isProcessing},on:{"click":_vm.onCloseAction}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }