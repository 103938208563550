import {AuthService} from "@/services/auth/auth.service";
import {AccountService} from "@/services/account/account.service";
import {AccountsService} from "@/services/accounts/accounts.service";
import {TournamentsService} from "@/services/tournaments/tournaments.service";
import {GamesService} from "@/services/games/games.service";
import {LolService} from "@/services/lol/lol.service";

const authService = AuthService.create()
const accountService = AccountService.create()
const accountsService = AccountsService.create();
const tournamentsService = TournamentsService.create();
const gamesService = GamesService.create()
const lolService = LolService.create();

export {
    authService,
    accountService,
    accountsService,
    tournamentsService,
    gamesService,
    lolService
}