





















import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {AccountGameAccountsModuleStoreModule, PopupsStoreModule} from "@/store";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {find} from "lodash";
import {NotificationService} from "@/components/Notifications/notification.service";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";

export interface IShowGameAccountPopupData {
  gameAccountId: number
}

@Component({})
export default class DisconnectGameAccountPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly data!: IShowGameAccountPopupData

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private isLoading: boolean = false

  get gameAccount (): IGameAccountEntity | null {
    const gameAccount = find(this.gameAccounts, (gameAccount: IGameAccountEntity) => gameAccount.id === this.data.gameAccountId)
    if (gameAccount) {
      return gameAccount
    }

    return null
  }

  private async onDisconnectAction () {
    if (this.gameAccount === null) {
      return
    }

    this.isLoading = true
    const deleteGameAccountResult = await AccountGameAccountsModuleStoreModule.deleteGameAccount(this.gameAccount.id)

    if (deleteGameAccountResult.isFailure()) {
      NotificationService.showErrorNotification(deleteGameAccountResult.error.message)
      this.isLoading = false
    } else {
      NotificationService.showSuccessNotification('Game account successfully disconnected from account')
      this.isLoading = false
      await PopupsStoreModule.hideActivePopup()
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
