import {NavigationGuard} from "vue-router";
import {CoreStoreModule} from "@/store";
import {CoreModuleGetterTypes} from "@/store/modules/core/types";

export const initializationGuard: NavigationGuard = (to, from, next) => {
    if (CoreStoreModule[CoreModuleGetterTypes.IS_INITIALIZED]) {
        next()
    } else {
        const interval = setInterval(() => {
            if (CoreStoreModule[CoreModuleGetterTypes.IS_INITIALIZED]) {
                clearInterval(interval)
                next()
            }
        }, 30)
    }
}