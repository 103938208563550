export enum RouteNames {
    HomeRoute = 'HomeRoute',
    AuthSignInRoute = 'AuthSignInRoute',
    AuthSignUpRoute = 'AuthSignUpRoute',
    AuthCallbackRoute = 'AuthCallbackRoute',
    DashboardRoute = 'DashboardRoute',
    AccountSettingsRoute = 'AccountSettingsRoute',
    AccountSettingsAccountRoute = 'AccountSettingsAccountRoute',
    AccountSettingsGameAccountsRoute = 'AccountSettingsGameAccountsRoute',
    AccountSettingsApiKeyRoute = 'AccountSettingsApiKeyRoute',
    StatsRoute = 'StatsRoute',
    LolWelcome = 'LolWelcome', // todo,
    UserProfileRoute = 'UserProfileRoute',
    FriendInvitationRoute = 'FriendInvitationRoute',
    CreateTournamentRoute = 'CreateTournamentRoute',
    TournamentsRoute = 'TournamentsRoute',
    AvailableTournamentsRoute = 'AvailableTournamentsRoute',
    ParticipatedTournamentsRoute = 'ParticipatedTournamentsRoute',
    OwnedTournamentsRoute = 'OwnedTournamentsRoute',
    CompletedTournamentsRoute = 'CompletedTournamentsRoute',
    TournamentRoute = 'TournamentRoute',
}

export enum AuthGuard {
    CANNOT_BE_AUTHENTICATED,
    MUST_BE_AUTHENTICATED
}