export class BackgroundChanger {

    public static maxImgId: number = 4;
    private static backgroundChangeIntervalValue: number = 60000;
    private static interval: any = undefined;
    private static running: boolean = false;

    private BackgroundChanger() {}

    public static setMaxImgId(value: number): void {
        BackgroundChanger.maxImgId = value;
    }

    public static startCounter(intervalCallback: () => void) {
        if (BackgroundChanger.running)  return;

        BackgroundChanger.running = true;
        BackgroundChanger.interval = setInterval(intervalCallback, BackgroundChanger.backgroundChangeIntervalValue)
    }

    public static stopCounter() {
        if (!BackgroundChanger.running) return;

        BackgroundChanger.running = false;
        clearInterval(BackgroundChanger.interval);
    }

    public static restartCounter(intervalCallback: () => void) {
        if (!BackgroundChanger.isRunning){
            BackgroundChanger.startCounter(intervalCallback);
        }
        else {
            BackgroundChanger.stopCounter();
            BackgroundChanger.startCounter(intervalCallback);
        }
    }

    public static isRunning(): boolean {
        return BackgroundChanger.running;
    }
}