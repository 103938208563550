




































import { Component, Prop, Vue } from 'vue-property-decorator'
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {SidebarsStoreModule} from "@/store";
import {RouteNames} from "@/router/types";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {IAuthUser} from "@/core/auth/types";
import SidebarLogo from "@/components/Sidebar/SidebarLogo.vue";
import {SidebarsModuleGetterTypes} from "@/store/modules/sidebars/types";
import { COLORS } from "@/core/helpers/color.types"

@Component({
  components: {SidebarLogo},
  enums: {
    RouteNames,
    COLORS
  }
})
export default class DefaultSidebar extends Vue {
  @Prop({required: true}) private readonly color!: COLORS;

  @Getter(SidebarsModuleGetterTypes.IS_LEFT_SIDEBAR_OPEN, { namespace: StoreNamespace.Sidebars })
  private readonly isSidebarOpen!: boolean

  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  get publicItems () {
    return [
      { title: 'home', route: { name: RouteNames.HomeRoute }, icon: 'mdi-home' },
      { title: 'stats', route: { name: RouteNames.LolWelcome }, icon: 'mdi-flask' },
    ]
  }

  get authItems () {
    return [
      { title: 'userProfile', route: { name: RouteNames.UserProfileRoute, params: { userId: this.user.id } }, icon: 'mdi-card-account-details' },
      { title: 'tournaments', route: { name: RouteNames.TournamentsRoute }, icon: 'mdi-tournament ' },
      { title: 'settings', route: { name: RouteNames.AccountSettingsRoute }, icon: 'mdi-cog' }
    ]
  }

  private onSidebarInput (val: boolean) {
    SidebarsStoreModule.toggleLeftSidebar(val)
  }
}
