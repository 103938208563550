import Vue from 'vue'

export class Clipboard {
    public copyText(str: string): Promise<string> {
        const el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        return Promise.resolve(str)
    }
}

const clipboard = new Clipboard()

Vue.prototype.$clipboard = clipboard