import store from "@/store/store";
import {StoreNamespace} from "@/store/types";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {tournamentsService} from "@/services";
import {Result} from "typescript-result";
import {
    ITournamentsModuleState,
    TournamentsModuleGetterTypes,
    TournamentsModuleMutationTypes
} from "@/store/modules/tournaments/types";
import {defaultVuexPaginatedData, IUpdatePaginationData, IVuexPaginatedData, VuexState} from "@/core/vuex/types";
import {IPagination, IPaginationParams} from "@/core/service/types";
import {TournamentTypes} from "@/core/tournaments/types";
import {ITournamentResponse} from "@/services/tournaments/types";
import {map} from 'lodash'

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Tournaments,
    store
})
class TournamentsModule extends VuexModule implements ITournamentsModuleState {
    availableTournamentsData: IVuexPaginatedData<ITournamentResponse[]> = defaultVuexPaginatedData
    dataPoolingInterval: NodeJS.Timeout | null = null

    get [TournamentsModuleGetterTypes.AVAILABLE_TOURNAMENTS] () {
        return map(this.availableTournamentsData.rows, (tournamentResponse) => {
            return {
                ...tournamentResponse.tournament,
                ...{ my_participation: tournamentResponse.data.my_participation,
                    current_participants: tournamentResponse.data.current_participants
                }
            }
        })
    }

    get [TournamentsModuleGetterTypes.AVAILABLE_TOURNAMENTS_PAGINATION] () {
        return this.availableTournamentsData.pagination
    }

    get [TournamentsModuleGetterTypes.AVAILABLE_TOURNAMENTS_VUEX_STATE] () {
        return this.availableTournamentsData.vuexState
    }

    @Mutation
    [TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS] (tournaments: ITournamentResponse[]) {
        this.availableTournamentsData = {...this.availableTournamentsData, ...{ rows: tournaments }}
    }

    @Mutation
    [TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_PAGINATION] (pagination: IPagination) {
        this.availableTournamentsData = {...this.availableTournamentsData, ...{ pagination: pagination }}
    }

    @Mutation
    [TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE] (vuexState: VuexState) {
        this.availableTournamentsData = {...this.availableTournamentsData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [TournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL] (dataPoolingInterval: NodeJS.Timeout | null) {
        this.dataPoolingInterval = dataPoolingInterval
    }

    @Action
    public async initializeAvailableTournaments (): Promise<void> {
        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)

        await this.setAvailableTournaments()
        this.startDataPooling()
    }

    @Action
    public async setAvailableTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>> {
        const getAvailableTournamentsResult = await tournamentsService.getAvailableTournaments(pagination)

        if (getAvailableTournamentsResult.isFailure() ||
            (getAvailableTournamentsResult.isSuccess() && getAvailableTournamentsResult.value.rows.length === 0)) {
            this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS, [])
            this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
            setTimeout(() => {
                this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE, VuexState.DATA_NOT_FOUND)
            }, 500)
            return Result.error([])
        }

        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS, getAvailableTournamentsResult.value.rows)
        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_PAGINATION, getAvailableTournamentsResult.value.pagination)

        setTimeout(() => {
            this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE, VuexState.INITIALIZED)
        }, 500)
        return Result.ok(getAvailableTournamentsResult.value.rows)
    }

    @Action
    public async onCurrentPaginationPageChange(data: IUpdatePaginationData<TournamentTypes>) {
        switch (data.dataType) {
            case TournamentTypes.Available:
                this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
                await this.setAvailableTournaments({
                    limit: defaultVuexPaginatedData.pagination.limit,
                    page: data.newCurrentPage - 1
                })
                break;
        }
    }

    @Action
    public startDataPooling (): void {
        if (this.dataPoolingInterval !== null) return

        this.context.commit(TournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, setInterval(async () => {
            if (this.availableTournamentsData.vuexState === VuexState.INITIALIZED) {
                await this.setAvailableTournaments({
                    limit: this.availableTournamentsData.pagination.limit,
                    page: this.availableTournamentsData.pagination.current_page
                })
            }
        }, 60000))
    }

    @Action
    public stopDataPooling (): void {
        if (this.dataPoolingInterval != null) {
            clearInterval(this.dataPoolingInterval)
            this.context.commit(TournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, null)
        }
    }

    @Action
    public clearAvailableTournaments (): void {
        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS, [])
        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
    }

    @Action
    public clearStore () {
        this.stopDataPooling()
        this.clearAvailableTournaments()
    }
}

export const TournamentsStoreModule = getModule(TournamentsModule)