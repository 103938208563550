import {NavigationGuard} from "vue-router";
import {AuthStoreModule} from "@/store";
import {AuthGuard, RouteNames} from "@/router/types";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {DefaultRedirectRoutes} from "@/router/redirect.routes";

export const authenticationGuard: NavigationGuard = (to, from, next) => {
    const mustBeAuthenticatedToAccessRoute = to.matched.some(record => record.meta.requiresAuth === AuthGuard.MUST_BE_AUTHENTICATED)

    if (mustBeAuthenticatedToAccessRoute && !AuthStoreModule[AuthModuleGetterTypes.IS_LOGGED_IN]) {
        switch (to.name) {
            case RouteNames.FriendInvitationRoute:
                if (to.query.code) {
                    next(DefaultRedirectRoutes.defaultAuthRouteName({ query: { invitation_code: to.query.code } }))
                } else {
                    next(DefaultRedirectRoutes.defaultAuthRouteName())
                }
                break
            default:
                next(DefaultRedirectRoutes.defaultAuthRouteName())
                break
        }
    } else if (to.name === RouteNames.AuthSignInRoute && AuthStoreModule[AuthModuleGetterTypes.IS_LOGGED_IN]) {
        next(DefaultRedirectRoutes.defaultMainRouteName())
    } else {
        next()
    }
}