






import { Component, Vue } from 'vue-property-decorator'
import {NotificationData, NotificationsEventBusEvents, NotificationType} from "@/components/Notifications/types";

@Component({})
export default class Notifications extends Vue {
  private snackbarOpen: boolean = false
  private text: string = 'Default text'
  private type: NotificationType = NotificationType.Info

  get color () {
    switch (this.type) {
      case NotificationType.Success:
        return null
      case NotificationType.Info:
        return 'blue darken-2'
      case NotificationType.Warning:
        return 'orange darken-2'
      case NotificationType.Error:
        return 'red darken-2'
    }
  }

  private created () {
    this.$eventBus.AddListener(NotificationsEventBusEvents.ShowNotification, this.OnShowNotification)
  }

  private beforeDestroy () {
    this.$eventBus.RemoveListener(NotificationsEventBusEvents.ShowNotification, this.OnShowNotification)
  }

  private OnShowNotification (data: NotificationData) {
    this.snackbarOpen = false
    this.type = data.type
    this.text = data.text
    this.snackbarOpen = true
  }
}
