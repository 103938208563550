import {IAccountEntity} from "@/core/accounts/account.entity";

export interface IAccountFriendsModuleState {
    friends: IAccountEntity[]
    invitedByAccount: IAccountEntity | null
    dataPoolingInterval: NodeJS.Timeout | null
}

export enum AccountFriendsModuleGetterTypes {
    FRIENDS = 'FRIENDS',
    INVITED_BY_ACCOUNT = 'INVITED_BY_ACCOUNT',
    INVITATION_CODE = 'INVITATION_CODE'
}

export enum AccountFriendsModuleMutationTypes {
    SET_FRIENDS = 'SET_FRIENDS',
    UPDATE_FRIEND = 'UPDATE_FRIEND',
    DELETE_FRIEND = 'DELETE_FRIEND',
    SET_INVITED_BY_ACCOUNT = 'SET_INVITED_BY_ACCOUNT',
    SET_INVITATION_CODE = 'SET_INVITATION_CODE',
    SET_DATA_POOLING_INTERVAL = 'SET_DATA_POOLING_INTERVAL'
}