import {IAccountEntity} from "@/core/accounts/account.entity";

export enum AuthServiceUrlPaths {
    SignInWithEmailAndPasswordUrl = '/signIn',
    SignUpWithEmailAndPasswordUrl = '/signUp',
    SignInWithGoogleUrl = '/google',
    SignInWithDiscordUrl = '/discord',
    SignInWithTwitchUrl = '/twitch',
}

export interface ICustomTokenResponse {
    custom_token: string
    is_new_user: boolean
    friend?: IAccountEntity
}

export interface ISignInWithEmailAndPasswordRequest {
    email: string
    password: string
    invitation_code?: string
}

export interface ISignUpWithEmailAndPasswordRequest {
    email: string
    displayName: string
    password: string
    password_confirmation: string
    invitation_code?: string
}

export interface ISignInWithProviderRequest {
    code: string
    invitation_code?: string
}