import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const accountSettingsRoute: RouteConfig = {
    path: '/settings',
    name: RouteNames.AccountSettingsRoute,
    component: () => import(/* webpackChunkName: "UserSettingsPage" */ '@/views/dashboard/settings/UserSettingsPage.vue'),
    redirect: { name: RouteNames.AccountSettingsAccountRoute },
    meta: {
        requiresAuth: AuthGuard.MUST_BE_AUTHENTICATED
    },
    children: [
        {
            path: '/settings/account',
            name: RouteNames.AccountSettingsAccountRoute,
            component: () => import(/* webpackChunkName: "UserSettingsAccountPage" */ '@/views/dashboard/settings/UserSettingsAccountPage.vue'),
        },
        {
            path: '/settings/game-accounts',
            name: RouteNames.AccountSettingsGameAccountsRoute,
            component: () => import(/* webpackChunkName: "UserSettingsGameAccountsPage" */ '@/views/dashboard/settings/UserSettingsGameAccountsPage.vue'),
        },
        {
            path: '/settings/api-key',
            name: RouteNames.AccountSettingsApiKeyRoute,
            component: () => import(/* webpackChunkName: "UserSettingsApiKeyPage" */ '@/views/dashboard/settings/UserSettingsApiKeyPage.vue'),
        },
    ]
}