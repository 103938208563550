import {Result} from "typescript-result";
import {TournamentTypes} from "@/core/tournaments/types";
import {IPaginationParams} from "@/core/service/types";
import {IUpdatePaginationData, IVuexPaginatedData} from "@/core/vuex/types";
import {ITournamentResponse} from "@/services/tournaments/types";

export interface ITournamentsModuleState {
    availableTournamentsData: IVuexPaginatedData<ITournamentResponse[]>
    dataPoolingInterval: NodeJS.Timeout | null

    initializeAvailableTournaments (): Promise<void>
    setAvailableTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>>
    onCurrentPaginationPageChange (data: IUpdatePaginationData<TournamentTypes>)
    startDataPooling (): void
    stopDataPooling (): void
    clearAvailableTournaments (): void
    clearStore (): void
}

export enum TournamentsModuleGetterTypes {
    AVAILABLE_TOURNAMENTS = 'AVAILABLE_TOURNAMENTS',
    AVAILABLE_TOURNAMENTS_PAGINATION = 'AVAILABLE_TOURNAMENTS_PAGINATION',
    AVAILABLE_TOURNAMENTS_VUEX_STATE = 'AVAILABLE_TOURNAMENTS_VUEX_STATE',
}

export enum TournamentsModuleMutationTypes {
    SET_AVAILABLE_TOURNAMENTS = 'SET_AVAILABLE_TOURNAMENTS',
    SET_AVAILABLE_TOURNAMENTS_PAGINATION = 'SET_AVAILABLE_TOURNAMENTS_PAGINATION',
    SET_AVAILABLE_TOURNAMENTS_VUEX_STATE = 'SET_AVAILABLE_TOURNAMENTS_VUEX_STATE',

    SET_DATA_POOLING_INTERVAL = 'SET_DATA_POOLING_INTERVAL'
}