import {NotificationData, NotificationsEventBusEvents, NotificationType} from "@/components/Notifications/types";
import {eventBus} from "@/plugins/eventBus";

export class NotificationService {
    public static showSuccessNotification = (text: string) => {
        const notificationData: NotificationData = {
            text: text,
            type: NotificationType.Success
        }
        eventBus.$emit(NotificationsEventBusEvents.ShowNotification, notificationData)
    }

    public static showInfoNotification = (text: string) => {
        const notificationData: NotificationData = {
            text: text,
            type: NotificationType.Info
        }
        eventBus.$emit(NotificationsEventBusEvents.ShowNotification, notificationData)
    }

    public static showWarningNotification = (text: string) => {
        const notificationData: NotificationData = {
            text: text,
            type: NotificationType.Warning
        }
        eventBus.$emit(NotificationsEventBusEvents.ShowNotification, notificationData)
    }

    public static showErrorNotification = (text: string) => {
        const notificationData: NotificationData = {
            text: text,
            type: NotificationType.Error
        }
        eventBus.$emit(NotificationsEventBusEvents.ShowNotification, notificationData)
    }
}