import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from '@/store/store'
import {StoreNamespace} from "@/store/types";
import {
    ISidebarsModuleState,
    SidebarsModuleGetterTypes,
    SidebarsModuleMutationTypes
} from "@/store/modules/sidebars/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Sidebars,
    store
})
class SidebarsModule extends VuexModule implements ISidebarsModuleState {
    isLeftSidebarOpen: boolean = false
    isRightSidebarOpen: boolean = false

    get [SidebarsModuleGetterTypes.IS_LEFT_SIDEBAR_OPEN] () {
        return this.isLeftSidebarOpen
    }

    get [SidebarsModuleGetterTypes.IS_RIGHT_SIDEBAR_OPEN] () {
        return this.isRightSidebarOpen
    }

    @Mutation
    [SidebarsModuleMutationTypes.SET_LEFT_SIDEBAR_OPEN] (value: boolean) {
        if (value === null) this.isLeftSidebarOpen = !this.isLeftSidebarOpen
        else this.isLeftSidebarOpen = value
    }

    @Mutation
    [SidebarsModuleMutationTypes.SET_RIGHT_SIDEBAR_OPEN] (value: boolean) {
        if (value === null) this.isRightSidebarOpen = !this.isRightSidebarOpen
        else this.isRightSidebarOpen = value
    }

    @Action
    public toggleLeftSidebar (value: boolean | null = null) {
        this.context.commit(SidebarsModuleMutationTypes.SET_LEFT_SIDEBAR_OPEN, value)
    }

    @Action
    public toggleRightSidebar (value: boolean | null = null) {
        this.context.commit(SidebarsModuleMutationTypes.SET_RIGHT_SIDEBAR_OPEN, value)
    }

    @Action
    public clearStore () {
        this.context.commit(SidebarsModuleMutationTypes.SET_LEFT_SIDEBAR_OPEN, false)
        this.context.commit(SidebarsModuleMutationTypes.SET_RIGHT_SIDEBAR_OPEN, false)
    }
}

export const SidebarsStoreModule = getModule(SidebarsModule)