import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {StoreNamespace} from "@/store/types";
import store from "@/store/store";
import {Result} from "typescript-result";
import {accountService} from "@/services";
import {IPagination, IPaginationParams, IServiceError} from "@/core/service/types";
import {
    AccountTournamentsModuleGetterTypes, AccountTournamentsModuleMutationTypes,
    IAccountTournamentsModuleState
} from "@/store/modules/account/tournaments/types";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {ICreateTournamentRequest} from "@/services/account/tournament.types";
import {defaultVuexPaginatedData, IUpdatePaginationData, IVuexPaginatedData, VuexState} from "@/core/vuex/types";
import {TournamentTypes} from "@/core/tournaments/types";
import {ITournamentResponse} from "@/services/tournaments/types";
import {map} from "lodash";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.AccountTournaments,
    store
})
class AccountTournamentsModule extends VuexModule implements IAccountTournamentsModuleState {
    participatedTournamentsData: IVuexPaginatedData<ITournamentResponse[]> = defaultVuexPaginatedData
    ownedTournamentsData: IVuexPaginatedData<ITournamentResponse[]> = defaultVuexPaginatedData
    completedTournamentsData: IVuexPaginatedData<ITournamentResponse[]> = defaultVuexPaginatedData
    dataPoolingInterval: NodeJS.Timeout | null = null

    get [AccountTournamentsModuleGetterTypes.PARTICIPATED_TOURNAMENTS] () {
        return map(this.participatedTournamentsData.rows, (tournamentResponse) => {
            return {
                ...tournamentResponse.tournament,
                ...{ my_participation: tournamentResponse.data.my_participation,
                    current_participants: tournamentResponse.data.current_participants
                }
            }
        })
    }

    get [AccountTournamentsModuleGetterTypes.PARTICIPATED_TOURNAMENTS_PAGINATION] () {
        return this.participatedTournamentsData.pagination
    }

    get [AccountTournamentsModuleGetterTypes.PARTICIPATED_TOURNAMENTS_VUEX_STATE] () {
        return this.participatedTournamentsData.vuexState
    }

    get [AccountTournamentsModuleGetterTypes.OWNED_TOURNAMENTS] () {
        return map(this.ownedTournamentsData.rows, (tournamentResponse) => {
            return {
                ...tournamentResponse.tournament,
                ...{ my_participation: tournamentResponse.data.my_participation,
                    current_participants: tournamentResponse.data.current_participants
                }
            }
        })
    }

    get [AccountTournamentsModuleGetterTypes.OWNED_TOURNAMENTS_PAGINATION] () {
        return this.ownedTournamentsData.pagination
    }

    get [AccountTournamentsModuleGetterTypes.OWNED_TOURNAMENTS_VUEX_STATE] () {
        return this.ownedTournamentsData.vuexState
    }

    get [AccountTournamentsModuleGetterTypes.COMPLETED_TOURNAMENTS] () {
        return map(this.completedTournamentsData.rows, (tournamentResponse) => {
            return {
                ...tournamentResponse.tournament,
                ...{ my_participation: tournamentResponse.data.my_participation,
                    current_participants: tournamentResponse.data.current_participants
                }
            }
        })
    }

    get [AccountTournamentsModuleGetterTypes.COMPLETED_TOURNAMENTS_PAGINATION] () {
        return this.completedTournamentsData.pagination
    }

    get [AccountTournamentsModuleGetterTypes.COMPLETED_TOURNAMENTS_VUEX_STATE] () {
        return this.completedTournamentsData.vuexState
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS] (tournaments: ITournamentResponse[]) {
        this.participatedTournamentsData = {...this.participatedTournamentsData, ...{ rows: tournaments }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_PAGINATION] (pagination: IPagination) {
        this.participatedTournamentsData = {...this.participatedTournamentsData, ...{ pagination: pagination }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE] (vuexState: VuexState) {
        this.participatedTournamentsData = {...this.participatedTournamentsData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS] (tournaments: ITournamentResponse[]) {
        this.ownedTournamentsData = {...this.ownedTournamentsData, ...{ rows: tournaments }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_PAGINATION] (pagination: IPagination) {
        this.ownedTournamentsData = {...this.ownedTournamentsData, ...{ pagination: pagination }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_VUEX_STATE] (vuexState: VuexState) {
        this.ownedTournamentsData = {...this.ownedTournamentsData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS] (tournaments: ITournamentResponse[]) {
        this.completedTournamentsData = {...this.completedTournamentsData, ...{ rows: tournaments }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_PAGINATION] (pagination: IPagination) {
        this.completedTournamentsData = {...this.completedTournamentsData, ...{ pagination: pagination }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_VUEX_STATE] (vuexState: VuexState) {
        this.completedTournamentsData = {...this.completedTournamentsData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [AccountTournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL] (dataPoolingInterval: NodeJS.Timeout | null) {
        this.dataPoolingInterval = dataPoolingInterval
    }

    @Action
    public async initializeParticipatedTournaments (): Promise<void> {
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)

        await this.setParticipatedTournaments()
        this.startDataPooling()
    }

    @Action
    public async initializeOwnedTournaments (): Promise<void> {
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)

        await this.setOwnedTournaments()
        this.startDataPooling()
    }

    @Action
    public async initializeCompletedTournaments (): Promise<void> {
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)

        await this.setCompletedTournaments()
        this.startDataPooling()
    }

    @Action
    public async setParticipatedTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>> {
        const getParticipatedTournamentsResult = await accountService.getParticipatedTournaments(pagination)

        if (getParticipatedTournamentsResult.isFailure() ||
            (getParticipatedTournamentsResult.isSuccess() && getParticipatedTournamentsResult.value.rows.length === 0)) {
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS, [])
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
            setTimeout(() => {
                this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE, VuexState.DATA_NOT_FOUND)
            }, 500)
            return Result.error([])
        }

        this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS, getParticipatedTournamentsResult.value.rows)
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_PAGINATION, getParticipatedTournamentsResult.value.pagination)

        setTimeout(() => {
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE, VuexState.INITIALIZED)
        }, 500)

        return Result.ok(getParticipatedTournamentsResult.value.rows)
    }

    @Action
    public async setOwnedTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>> {
        const getOwnedTournamentsResult = await accountService.getOwnedTournaments(pagination)

        if (getOwnedTournamentsResult.isFailure() ||
            (getOwnedTournamentsResult.isSuccess() && getOwnedTournamentsResult.value.rows.length === 0)) {
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS, [])
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
            setTimeout(() => {
                this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_VUEX_STATE, VuexState.DATA_NOT_FOUND)
            }, 500)
            return Result.error([])
        }

        this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS, getOwnedTournamentsResult.value.rows)
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_PAGINATION, getOwnedTournamentsResult.value.pagination)

        setTimeout(() => {
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_VUEX_STATE, VuexState.INITIALIZED)
        }, 500)

        return Result.ok(getOwnedTournamentsResult.value.rows)
    }

    @Action
    public async setCompletedTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>> {
        const getCompletedTournamentsResult = await accountService.getCompletedTournaments(pagination)

        if (getCompletedTournamentsResult.isFailure() ||
            (getCompletedTournamentsResult.isSuccess() && getCompletedTournamentsResult.value.rows.length === 0)) {
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS, [])
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
            setTimeout(() => {
                this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_VUEX_STATE, VuexState.DATA_NOT_FOUND)
            }, 500)
            return Result.error([])
        }

        this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS, getCompletedTournamentsResult.value.rows)
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_PAGINATION, getCompletedTournamentsResult.value.pagination)

        setTimeout(() => {
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_VUEX_STATE, VuexState.INITIALIZED)
        }, 500)

        return Result.ok(getCompletedTournamentsResult.value.rows)
    }

    @Action
    public async createTournament(content: ICreateTournamentRequest): Promise<Result<IServiceError, ITournamentEntity>> {
        const createTournamentResult = await accountService.createTournament(content)

        if (createTournamentResult.isFailure()) {
            return Result.error(createTournamentResult.error)
        }

        return Result.ok(createTournamentResult.value)
    }

    @Action
    public async onCurrentPaginationPageChange (data: IUpdatePaginationData<TournamentTypes>) {
        switch (data.dataType) {
            case TournamentTypes.Participated:
                this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
                await this.setParticipatedTournaments({
                    limit: defaultVuexPaginatedData.pagination.limit,
                    page: data.newCurrentPage - 1
                })
                break;
            case TournamentTypes.Owned:
                this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
                await this.setOwnedTournaments({
                    limit: defaultVuexPaginatedData.pagination.limit,
                    page: data.newCurrentPage - 1
                })
                break;
            case TournamentTypes.Completed:
                this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
                await this.setCompletedTournaments({
                    limit: defaultVuexPaginatedData.pagination.limit,
                    page: data.newCurrentPage - 1
                })
                break;
        }
    }

    @Action
    public startDataPooling (): void {
        if (this.dataPoolingInterval !== null) return

        this.context.commit(AccountTournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, setInterval(async () => {
            if (this.participatedTournamentsData.vuexState === VuexState.INITIALIZED) {
                await this.setParticipatedTournaments({
                    limit: this.participatedTournamentsData.pagination.limit,
                    page: this.participatedTournamentsData.pagination.current_page
                })
            }

            if (this.ownedTournamentsData.vuexState === VuexState.INITIALIZED) {
                await this.setOwnedTournaments({
                    limit: this.ownedTournamentsData.pagination.limit,
                    page: this.ownedTournamentsData.pagination.current_page
                })
            }

            if (this.completedTournamentsData.vuexState === VuexState.INITIALIZED) {
                await this.setCompletedTournaments({
                    limit: this.completedTournamentsData.pagination.limit,
                    page: this.completedTournamentsData.pagination.current_page
                })
            }
        }, 60000))
    }

    @Action
    public stopDataPooling (): void {
        if (this.dataPoolingInterval != null) {
            clearInterval(this.dataPoolingInterval)
            this.context.commit(AccountTournamentsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, null)
        }
    }

    @Action
    public clearParticipatedTournaments (): void {
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS, [])
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
    }

    @Action
    public clearOwnedTournaments (): void {
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS, [])
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_OWNED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
    }

    @Action
    public clearCompletedTournaments (): void {
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS, [])
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
        this.context.commit(AccountTournamentsModuleMutationTypes.SET_COMPLETED_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
    }

    @Action
    public clearStore () {
        this.stopDataPooling()
        this.clearParticipatedTournaments()
        this.clearOwnedTournaments()
        this.clearCompletedTournaments()
    }
}

export const AccountTournamentsModuleStoreModule = getModule(AccountTournamentsModule)