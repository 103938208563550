import { RouteConfig } from 'vue-router'
import {AuthGuard, RouteNames} from '@/router/types'

export const authCallbackRoute: RouteConfig = {
    path: '/auth/:provider',
    name: RouteNames.AuthCallbackRoute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "SignInPage" */ '@/views/auth/AuthCallbackPage.vue'),
    meta: {
        requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
    }
}