import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const tournamentRoute: RouteConfig = {
    path: '/tournaments/:tournamentId',
    name: RouteNames.TournamentRoute,
    component: () => import(/* webpackChunkName: "TournamentPage" */ '@/views/tournaments/TournamentPage.vue'),
    meta: {
        requiresAuth: AuthGuard.MUST_BE_AUTHENTICATED
    }
}