






































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {AccountGameAccountsModuleStoreModule, AccountStoreModule} from "@/store";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {NotificationService} from "@/components/Notifications/notification.service";
import {Timing} from "@/core/helpers/Timing";
import { Moment } from "moment";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {ICreateLeagueOfLegendsGameAccountRequest} from "@/services/account/types";
import {IAuthUser} from "@/core/auth/types";

@Component({
  methods: {
    formatDuration: Timing.formatDuration
  }
})
export default class VerifyLeagueOfLegendsAccountPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly formData!: ICreateLeagueOfLegendsGameAccountRequest

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  @Getter(AccountModuleGetterTypes.ACCOUNT, { namespace: StoreNamespace.Account })
  private readonly account!: IAccountEntity

  get riotVerificationCodeLifetime () {
    const diff = Timing.getTimeDiff(this.dateTimeNow, Timing.UnixToMoment(this.account.riot_verification_code_expiration_time))
    if (diff > 0) {
      return diff
    } else {
      return 0
    }
  }

  // Timer
  private interval: NodeJS.Timeout | null = null
  private dateTimeNow: Moment = Timing.getTimeNow()

  private isLoading: boolean = false
  private isProcessing: boolean = false
  private isError: boolean = false

  private created () {
    this.refreshRiotVerificationCode()
  }

  private refreshRiotVerificationCode () {
    this.isLoading = true
    AccountStoreModule.refreshRiotVerificationCode().then(() => {
      this.startTimer()
      this.isLoading = false
    })
  }

  private onRegenerateCodeClick () {
    this.isError = false
    this.refreshRiotVerificationCode()
  }

  private onRiotVerificationCodeCopyClick () {
    if (this.account.riot_verification_code === null) {
      NotificationService.showErrorNotification(`Riot verification code is can't copy to the clipboard`)
      return
    }

    this.$clipboard.copyText(this.account.riot_verification_code).then(() => {
       NotificationService.showSuccessNotification('Riot verification code is copied to the clipboard')
    }).catch(() => {
       NotificationService.showErrorNotification(`Riot verification code is can't copy to the clipboard`)
    })
  }

  private async onSubmitAction () {
    this.isError = false
    this.isProcessing = true

    const createLeagueOfLegendsGameAccountResult = await AccountGameAccountsModuleStoreModule.createLeagueOfLegendsGameAccount(this.formData)

    if (createLeagueOfLegendsGameAccountResult.isFailure()) {
      this.isError = true
      this.isProcessing = false
    } else {
      this.onSuccessVerifyAction(createLeagueOfLegendsGameAccountResult.value.name)
    }
  }

  @Emit()
  private onSuccessVerifyAction (name: string) {
    return `You are now connected with ${name}`
  }

  @Emit()
  private onCloseAction () {
    return
  }

  private startTimer () {
    this.interval = setInterval(() => {
      this.dateTimeNow = Timing.getTimeNow()
    }, 1000)
  }

  private stopTimer () {
    if (this.interval != null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  beforeDestroy () {
    this.stopTimer()
  }

  @Watch('riotVerificationCodeLifetime')
  private onRiotVerificationCodeLifetimeChanged (val: number, oldVal: number) {
    if (oldVal !== 0 && val === 0) {
      this.refreshRiotVerificationCode()
    }
  }
}
