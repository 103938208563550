





















import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {accountService} from "@/services";
import {NotificationService} from "../../Notifications/notification.service";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {PopupsStoreModule} from "@/store";
import {RouteNames} from "@/router/types";

export interface ITournamentRemoveParticipantPopupData {
  tournament: ITournamentEntity
}

@Component({})
export default class TournamentRemoveParticipantPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly data!: ITournamentRemoveParticipantPopupData

  private isProcessing: boolean = false

  private async onCancelTournamentAction () {
    if (!this.data.tournament) return

    this.isProcessing = true
    const cancelTournamentResult = await accountService.cancelTournament(this.data.tournament.id)

    if (cancelTournamentResult.isFailure()) {
      NotificationService.showErrorNotification(cancelTournamentResult.error.message)
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully canceled a tournament')
      this.$router.push({ name: RouteNames.TournamentsRoute }).then(() => {
        this.onCloseAction()
      })
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
