export enum COLORS {
    PRIMARY = "custom_dark",
    PRIMARY_LIGHT = "custom_dark_lighten",
    PRIMARY_DARK = "custom_dark_darken",
    PRIMARY_BASE = 'rgb(18, 18, 18)',
    SECONDARY = "secondary",
    SECONDRAY_LIGHT = "secondary_light",
    SECONDARY_DARK = "secondary_dark",
    ACCENT = "accent",
    GREEN = "green",
    RED = "red",
    GREY = "grey",
    OPACITY_M = "rgb(0, 0, 0, 0.8)",
    OPACITY_S = "rgb(30, 30, 30, 0.8)",
}