import {RouteConfig} from "vue-router";

// public route
import { defaultRoute } from "@/router/routes/public/DefaultRoute"
import {homeRoute} from "@/router/routes/public/HomeRoute";
import {statsRoute} from "@/router/routes/public/StatsRoute";
import {userProfilePage} from "@/router/routes/public/UserProfilePage";

// auth routes
import {authSignInRoute} from "@/router/routes/auth/AuthSignInRoute";
import {authSignUpRoute} from "@/router/routes/auth/AuthSignUpRoute";
import {authCallbackRoute} from "@/router/routes/auth/AuthCallbackRoute";

// dashboard routes
import {dashboardRoute} from "@/router/routes/dashboard/DashboardRoute";
import {accountSettingsRoute} from "@/router/routes/dashboard/AccountSettingsRoute";

// friends routes
import { friendInvitationRoute } from "@/router/routes/friends/FriendInvitationRoute"

// tournaments routes
import { createTournamentRoute } from "@/router/routes/tournaments/CreateTournamentRoute"
import { tournamentsRoute } from "@/router/routes/tournaments/TournamentsRoute"
import { tournamentRoute } from "@/router/routes/tournaments/TournamentRoute"

const routes: RouteConfig[] = [
    homeRoute,
    authSignInRoute,
    authSignUpRoute,
    authCallbackRoute,
    dashboardRoute,
    statsRoute,
    accountSettingsRoute,
    userProfilePage,
    friendInvitationRoute,
    createTournamentRoute,
    tournamentsRoute,
    tournamentRoute,
    defaultRoute,
]

export {
    routes
}