


































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentCardParticipantsTableData} from "../TournamentCard/TournamentCardParticipantsTable.vue";
import {accountService} from "@/services";
import {NotificationService} from "../../Notifications/notification.service";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {CurrentTournamentStoreModule, PopupsStoreModule} from "@/store";
import {ITournamentUpdateParticipantRequest} from "@/services/account/tournament.types";
import {ParticipantRole} from "@/core/tournaments/tournament-participant.entity";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";

export interface ITournamentUpdateParticipantPopupData {
  tournament: ITournamentEntity
  participant: ITournamentCardParticipantsTableData
}

@Component({
  components: {ValidationObserver, ValidationProvider}
})
export default class TournamentUpdateParticipantPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly data!: ITournamentUpdateParticipantPopupData

  private isProcessing: boolean = false
  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: ITournamentUpdateParticipantRequest = {
    role: ParticipantRole.NORMAL,
    my_game_account_id: undefined
  }

  get tournamentParticipantRoles (): Form.ISelectStringValueOption[] {
    return [
      { text: ParticipantRole.NORMAL, value: ParticipantRole.NORMAL, disabled: false },
      { text: ParticipantRole.MODERATOR, value: ParticipantRole.MODERATOR, disabled: false },
    ]
  }

  private mounted () {
    if (this.$refs.updateTournamentParticipantValidationObserver) {
      this.formObserver = this.$refs.updateTournamentParticipantValidationObserver as InstanceType<typeof ValidationObserver>
    }
    this.setDefaultParticipantData()
  }

  private setDefaultParticipantData () {
    if (!this.data.tournament || !this.data.participant) return

    this.form.role = this.data.participant.role
    if (this.data.tournament.my_participation) {
      this.form.my_game_account_id = this.data.tournament.my_participation.game_account_id
    }
  }

  private async onUpdateTournamentParticipantAction () {
    if (!this.data.tournament || !this.data.participant) return

    this.isProcessing = true
    const joinToTournamentResult = await accountService.updateTournamentParticipant(this.data.tournament.id, this.data.participant.id, this.form)

    if (joinToTournamentResult.isFailure()) {
      NotificationService.showErrorNotification(joinToTournamentResult.error.message)
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully updated participant')
      CurrentTournamentStoreModule.refreshCurrentTournamentParticipants().then(() => {
        this.onCloseAction()
      })
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
