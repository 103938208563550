





























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {PopupsStoreModule} from "@/store";
import {Getter} from "vuex-class";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {StoreNamespace} from "@/store/types";
import {NotificationService} from "@/components/Notifications/notification.service";
import {PopupType} from "@/core/popups/types";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {IAuthUser} from "@/core/auth/types";

@Component({
})
export default class CopyFriendInvitationLinkPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  @Getter(AccountModuleGetterTypes.ACCOUNT, { namespace: StoreNamespace.Account })
  private readonly account!: IAccountEntity

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  get invitationLink(): string {
    return `${process.env.VUE_APP_BASE_URL}/friends/invite?code=${this.account.invitation_code}`;
  }

  private onInvitationLinkCopyClick() {
    this.$clipboard.copyText(this.invitationLink).then(() => {
      NotificationService.showSuccessNotification('Invitation link copied to the clipboard')
    }).catch(() => {
      NotificationService.showErrorNotification(`Cannot copy link`)
    })
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
