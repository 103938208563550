














import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {RouteNames} from "@/router/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  enums: {
    RouteNames
  },
  components: { BaseButtonRounded }
})
export default class GameAccountListItem extends Vue {
  @Prop({ type: Object, required: true, default: () => {} }) private readonly gameAccount!: IGameAccountEntity
  @Prop({ type: Boolean, required: false, default: () => false }) private readonly showButton!: boolean
}
