import store from "@/store/store";
import {StoreNamespace} from "@/store/types";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {accountService, accountsService} from "@/services";
import {Result} from "typescript-result";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {IServiceError} from "@/core/service/types";
import {IAddFriendRequest} from "@/services/account/types";
import {
    AccountFriendsModuleGetterTypes, AccountFriendsModuleMutationTypes,
    IAccountFriendsModuleState
} from "@/store/modules/account/friends/types";
import Vue from "vue";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.AccountFriends,
    store
})
class AccountFriendsModule extends VuexModule implements IAccountFriendsModuleState {
    friends: IAccountEntity[] = []
    invitedByAccount: IAccountEntity | null = null
    dataPoolingInterval: NodeJS.Timeout | null = null

    get [AccountFriendsModuleGetterTypes.FRIENDS] () { return this.friends }

    get [AccountFriendsModuleGetterTypes.INVITED_BY_ACCOUNT] () { return this.invitedByAccount }

    get [AccountFriendsModuleGetterTypes.INVITATION_CODE] () {
        if (!Vue.prototype.$cookies.isKey(AccountFriendsModuleGetterTypes.INVITATION_CODE)) {
            return null
        } else {
            return Vue.prototype.$cookies.get(AccountFriendsModuleGetterTypes.INVITATION_CODE)
        }
    }

    @Mutation
    [AccountFriendsModuleMutationTypes.SET_FRIENDS] (friends: IAccountEntity[]) {
        this.friends = friends
    }

    @Mutation
    [AccountFriendsModuleMutationTypes.UPDATE_FRIEND] (friend: IAccountEntity) {
        const index = this.friends.findIndex((currentFriend) => currentFriend.id === friend.id)
        if (index !== -1) {
            this.friends.splice(index, 1, friend)
        } else {
            this.friends.push(friend)
        }
    }

    @Mutation
    [AccountFriendsModuleMutationTypes.DELETE_FRIEND] (friendId: string) {
        const index = this.friends.findIndex((friend) => friend.id === friendId)
        if (index !== -1) {
            this.friends.splice(index, 1)
        }
    }

    @Mutation
    [AccountFriendsModuleMutationTypes.SET_INVITED_BY_ACCOUNT] (invitedByAccount: IAccountEntity | null) {
        this.invitedByAccount = invitedByAccount
    }

    @Mutation
    [AccountFriendsModuleMutationTypes.SET_INVITATION_CODE] (invitationCode: string | null) {
        if (invitationCode === null || invitationCode === undefined) {
            Vue.prototype.$cookies.remove(AccountFriendsModuleGetterTypes.INVITATION_CODE)
        } else {
            Vue.prototype.$cookies.set(AccountFriendsModuleGetterTypes.INVITATION_CODE, invitationCode)
        }
    }

    @Mutation
    [AccountFriendsModuleMutationTypes.SET_DATA_POOLING_INTERVAL] (dataPoolingInterval: NodeJS.Timeout | null) {
        this.dataPoolingInterval = dataPoolingInterval
    }

    @Action
    public async initialize () {
        await this.getFriends()
        this.startDataPooling()
    }

    @Action
    public async getFriends(): Promise<Result<[], IAccountEntity[]>> {
        const getFriendsResult = await accountService.getFriends()

        if (getFriendsResult.isFailure()) {
            return Result.error([])
        }

        this.context.commit(AccountFriendsModuleMutationTypes.SET_FRIENDS, getFriendsResult.value)
        return Result.ok(getFriendsResult.value)
    }

    @Action
    public async addFriend(content: IAddFriendRequest): Promise<Result<IServiceError, IAccountEntity>> {
        const addFriendResult = await accountService.addFriend(content)

        if (addFriendResult.isFailure()) {
            return Result.error(addFriendResult.error)
        }

        this.context.commit(AccountFriendsModuleMutationTypes.UPDATE_FRIEND, addFriendResult.value)
        return Result.ok(addFriendResult.value)
    }

    @Action
    public async deleteFriend(friendId: string): Promise<Result<IServiceError, void>> {
        const deleteFriendResult = await accountService.deleteFriendById(friendId)

        if (deleteFriendResult.isFailure()) {
            return Result.error(deleteFriendResult.error)
        }

        this.context.commit(AccountFriendsModuleMutationTypes.DELETE_FRIEND, friendId)
        return Result.ok()
    }

    @Action
    public async setInvitedAccountByInviteCode(invitationCode: string): Promise<Result<IServiceError, IAccountEntity>> {
        const getAccountByInvitationCodeResult = await accountsService.getAccountByInvitationCode(invitationCode)

        if (getAccountByInvitationCodeResult.isFailure()) {
            return Result.error(getAccountByInvitationCodeResult.error)
        }

        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITED_BY_ACCOUNT, getAccountByInvitationCodeResult.value)
        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITATION_CODE, invitationCode)
        return Result.ok(getAccountByInvitationCodeResult.value)
    }

    @Action
    public setInvitedAccount(invitedByAccount: IAccountEntity | undefined): Result<null, IAccountEntity> {
        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITATION_CODE, null)
        if (invitedByAccount === null || invitedByAccount === undefined) {
            this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITED_BY_ACCOUNT, null)
            return Result.error(null)
        }

        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITED_BY_ACCOUNT, invitedByAccount)

        return Result.ok(invitedByAccount)
    }

    @Action
    public removeInvitationCode () {
        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITATION_CODE, null)
    }

    @Action
    public removeInvitedByAccount () {
        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITED_BY_ACCOUNT, null)
    }

    @Action
    public startDataPooling (): void {
        if (this.dataPoolingInterval !== null) return

        this.context.commit(AccountFriendsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, setInterval(async () => {
            await this.getFriends()
        }, 60000))
    }

    @Action
    public stopDataPooling (): void {
        if (this.dataPoolingInterval != null) {
            clearInterval(this.dataPoolingInterval)
            this.context.commit(AccountFriendsModuleMutationTypes.SET_DATA_POOLING_INTERVAL, null)
        }
    }

    @Action
    public clearStore () {
        this.stopDataPooling()
        this.context.commit(AccountFriendsModuleMutationTypes.SET_FRIENDS, [])
        this.context.commit(AccountFriendsModuleMutationTypes.SET_INVITED_BY_ACCOUNT, null)
    }
}

export const AccountFriendsModuleStoreModule = getModule(AccountFriendsModule)