































import {Component} from "vue-property-decorator";
import Vue from "vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import ConnectGameAccountButton from "@/components/GameAccounts/ConnectGameAccountButton.vue";
import ConnectGameAccountSmallButton from "@/components/GameAccounts/ConnectGameAccountSmallButton.vue";
import {RouteNames} from "@/router/types";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import GameAccountListItem from "@/components/GameAccounts/GameAccountListItem.vue";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";

@Component({
  components: {GameAccountListItem, ConnectGameAccountSmallButton, ConnectGameAccountButton, BaseButtonRounded},
  enums: {RouteNames}
})
export default class MyGameAccountsList extends Vue {
  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private onAddNewAccount () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.ConnectGameAccount
    })
  }

  get buttonText (): string {
    if (this.gameAccounts.length > 0) {
      return 'connect-game-acc'
    }

    return 'connect-first-game-acc'
  }

}
