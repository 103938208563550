import {BaseAxiosService} from "@/core/service/base.service";
import {Result} from "typescript-result";
import {IPaginationParams, IPaginationResult, IServiceError} from "@/core/service/types";
import {
    ITournamentParticipantsResponse,
    ITournamentResponse,
    TournamentsServiceUrlPaths
} from "@/services/tournaments/types";

export class TournamentsService extends BaseAxiosService {
    constructor() {
        super(process.env.VUE_APP_TOURNAMENTS_SERVICE_URL as string)
        this.bindRequestInterceptor()
        this.bindResponseInterceptor()
    }

    public static create () {
        return new TournamentsService()
    }

    public getAvailableTournaments (pagination?: IPaginationParams): Promise<Result<IServiceError, IPaginationResult<ITournamentResponse[]>>> {
        return this.executeGet<IPaginationResult<ITournamentResponse[]>>(
            TournamentsServiceUrlPaths.GetTournamentsURL, { params: pagination ? pagination : {} }
        )
    }

    public getTournamentById (tournamentId: number): Promise<Result<IServiceError, ITournamentResponse>> {
        return this.executeGet<ITournamentResponse>(
            TournamentsServiceUrlPaths.GetTournamentByIdURL
                .replace('{tournamentId}', tournamentId.toString())
        )
    }

    public getTournamentParticipants (tournamentId: number, pagination?: IPaginationParams): Promise<Result<IServiceError, ITournamentParticipantsResponse>> {
        return this.executeGet<ITournamentParticipantsResponse>(
            TournamentsServiceUrlPaths.GetTournamentParticipantsURL
                .replace('{tournamentId}', tournamentId.toString()), { params: pagination ? pagination : {} }
        )
    }
}