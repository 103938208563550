export enum PopupType {
    Welcome,
    // gameAccounts -- start
    ConnectGameAccount,
    DisconnectGameAccount,
    // gameAccounts -- end
    // friends -- start
    AddFriend,
    CopyFriendInvitationLink,
    NewFriendInfo,
    DeleteFriend,
    // friends -- end
    // tournaments -- start
    TournamentJoin,
    TournamentSendAddRequest,
    TournamentRemoveParticipant,
    TournamentAcceptAddRequest,
    TournamentUpdateParticipant,
    TournamentCancel,
    // tournaments -- end
    // account -- start
    UpdateAccountDisplayNamePopup,
    UpdateAccountEmailPopup,
    UpdateAccountPasswordPopup
    // account -- end
}

export interface IPopupsData {
    type: PopupType
    queue: PopupsQueueType
    order: number,
    isActive: boolean
    isInstantiated: boolean
    data: IPopupDataDictionary
}

export interface IPopupDataDictionary {
    [key: string]: any
}

export interface ISetPopupData {
    popupType: PopupType
    data: IPopupDataDictionary
}

export interface IShowPopupDto {
    popupType: PopupType,
    data?: IPopupDataDictionary
}

export enum PopupsQueueType {
    None,
    AfterAuthentication
}