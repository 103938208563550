




























































import {Component} from "vue-property-decorator";
import Vue from "vue";
import WelcomePopup from "@/components/Popups/WelcomePopup.vue";
import {Getter} from "vuex-class";
import {PopupsModuleGetterTypes} from "@/store/modules/popups/types";
import {StoreNamespace} from "@/store/types";
import ConnectGameAccountContainer from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountContainer.vue";
import DisconnectGameAccountPopup from "@/components/GameAccounts/DisconnectGameAccountPopup.vue";
import {IPopupsData, PopupType} from "@/core/popups/types";
import {getPopupData} from "@/core/popups/helpers";
import NewFriendInfoPopup from "../Friends/NewFriendInfoPopup.vue"
import CopyFriendInvitationLinkPopup from "@/components/Friends/CopyFriendInvitationLinkPopup.vue";
import DeleteFriendPopup from "@/components/Friends/DeleteFriendPopup.vue";
import AddFriendPopup from "@/components/Friends/AddFriendPopup.vue";
import TournamentJoinPopup from "@/components/Tournaments/Popups/TournamentJoinPopup.vue";
import TournamentRemoveParticipantPopup from "@/components/Tournaments/Popups/TournamentRemoveParticipantPopup.vue";
import TournamentUpdateParticipantPopup from "@/components/Tournaments/Popups/TournamentUpdateParticipantPopup.vue";
import TournamentAcceptAddRequestPopup from "@/components/Tournaments/Popups/TournamentAcceptAddRequestPopup.vue";
import TournamentSendAddRequestPopup from "@/components/Tournaments/Popups/TournamentSendAddRequestPopup.vue";
import TournamentCancelPopup from "@/components/Tournaments/Popups/TournamentCancelPopup.vue";
import UpdateAccountDisplayNamePopup from "@/components/UserSettings/UpdateAccountDisplayNamePopup.vue";
import UpdateAccountEmailPopup from "@/components/UserSettings/UpdateAccountEmailPopup.vue";
import UpdateAccountPasswordPopup from "@/components/UserSettings/UpdateAccountPasswordPopup.vue";

@Component({
  components: {
    UpdateAccountPasswordPopup,
    UpdateAccountEmailPopup,
    UpdateAccountDisplayNamePopup,
    TournamentCancelPopup,
    TournamentSendAddRequestPopup,
    TournamentAcceptAddRequestPopup,
    TournamentUpdateParticipantPopup,
    TournamentRemoveParticipantPopup,
    TournamentJoinPopup,
    CopyFriendInvitationLinkPopup,
    DisconnectGameAccountPopup, ConnectGameAccountContainer, WelcomePopup, DeleteFriendPopup, NewFriendInfoPopup, AddFriendPopup},
  enums: {
    PopupType
  }
})
export default class PopupsContainer extends Vue {
  @Getter(PopupsModuleGetterTypes.POPUPS_DATA, { namespace: StoreNamespace.Popups })
  private readonly popupsData!: IPopupsData[]

  get welcomePopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.Welcome) }

  get connectGameAccountPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.ConnectGameAccount) }

  get disconnectGameAccountPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.DisconnectGameAccount) }

  get addFriendPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.AddFriend) }

  get copyFriendInvitationLinkPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.CopyFriendInvitationLink) }

  get newFriendInfoPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.NewFriendInfo) }

  get deleteFriendPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.DeleteFriend) }

  get tournamentJoinPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.TournamentJoin) }

  get tournamentRemoveParticipantPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.TournamentRemoveParticipant) }

  get tournamentUpdateParticipantPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.TournamentUpdateParticipant) }

  get tournamentAcceptAddRequestPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.TournamentAcceptAddRequest) }

  get tournamentSendAddRequestPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.TournamentSendAddRequest) }

  get tournamentCancelPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.TournamentCancel) }

  get updateAccountDisplayNamePopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.UpdateAccountDisplayNamePopup) }

  get updateAccountEmailPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.UpdateAccountEmailPopup) }

  get updateAccountPasswordPopupData (): IPopupsData | null { return getPopupData(this.popupsData, PopupType.UpdateAccountPasswordPopup) }
}
