export enum NotificationType {
    Success,
    Info,
    Warning,
    Error
}

export interface NotificationData {
    text: string,
    type: NotificationType
}

export enum NotificationsEventBusEvents {
    ShowNotification = 'ShowNotification'
}