import axios, { AxiosResponse } from "axios";
import { ISummonerSPellsData } from "./types";

export class LolDataDragonAssetsApi {
    private static DDragonLink: string = process.env.VUE_APP_LOL_DDRAGON_HOST as string;

    private constructor() {}

    public static championSplashImgUri(championName: string, skinNumber: number = 0) {
        return `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${championName}_${skinNumber}.jpg`;
    }

    public static profileIconUri(iconId: number) {
        return `${this.DDragonLink}/img/profileicon/${iconId}.png`;
    }

    public static championIconUri(championName: string) : string {
        return `${this.DDragonLink}/img/champion/${championName}.png`;
    }

    public static itemIconUri(itemId: number): string {
        return `${this.DDragonLink}/img/item/${itemId}.png`
    }
    
    public static summonerSpellsJson(): Promise<AxiosResponse<any>> {
        return axios.get(`${this.DDragonLink}/data/en_US/summoner.json`);
    } 

    public static runesJson(): Promise<AxiosResponse<any>> {
        return axios.get(`${this.DDragonLink}/data/en_US/runesReforged.json`);
    }

    public static itemsJson(): Promise<AxiosResponse<any>> {
        return axios.get(`${this.DDragonLink}/data/en_US/item.json`);
    }

    public static summonerSpellIconUri(spellName: string) {
        return `${this.DDragonLink}/img/spell/${spellName}`;
    }
}