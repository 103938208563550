
























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import ConnectGameAccountPopup from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountPopup.vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {AccountFriendsModuleStoreModule, PopupsStoreModule} from "@/store";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import {IAccountEntity} from "@/core/accounts/account.entity";

@Component({
  components: {ConnectGameAccountPopup}
})
export default class NewFriendInfoPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  @Getter(AccountFriendsModuleGetterTypes.INVITED_BY_ACCOUNT, { namespace: StoreNamespace.AccountFriends })
  private readonly invitedByAccount!: IAccountEntity

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup().then(() => {
      AccountFriendsModuleStoreModule.setInvitedAccount(undefined)
    })
  }
}
