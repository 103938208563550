import {IAccountEntity} from "@/core/accounts/account.entity";

export interface IAccountModuleState {
    account: IAccountEntity | null
}

export enum AccountModuleGetterTypes {
    ACCOUNT = 'ACCOUNT',
}

export enum AccountModuleMutationTypes {
    SET_ACCOUNT = 'SET_ACCOUNT',
}