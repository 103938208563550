



























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {PopupsStoreModule} from "@/store";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import ConnectGameAccountPopup from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountPopup.vue";
import {PopupType} from "@/core/popups/types";
import {IAuthUser} from "@/core/auth/types";

@Component({
  components: {ConnectGameAccountPopup}
})
export default class WelcomePopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  private onContinueClick () {
    PopupsStoreModule.hideActivePopup()
  }

  private onConnectGameAccountClick () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.ConnectGameAccount
    })
  }
}
