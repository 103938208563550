





































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {accountService} from "@/services";
import {NotificationService} from "../../Notifications/notification.service";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {CurrentTournamentStoreModule, PopupsStoreModule} from "@/store";
import {Getter} from "vuex-class";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import {StoreNamespace} from "@/store/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {ITournamentJoinRequest} from "@/services/account/tournament.types";
import {RouteNames} from "@/router/types";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";

export interface ITournamentAcceptAddRequestPopupData {
  tournament: ITournamentEntity
  withRedirect: boolean
}

@Component({
  components: {ValidationObserver, ValidationProvider}
})
export default class TournamentAcceptAddRequestPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly data!: ITournamentAcceptAddRequestPopupData

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private isLoading: boolean = false
  private isProcessing: boolean = false
  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: ITournamentJoinRequest = {
    my_game_account_id: undefined
  }

  private mounted () {
    if (this.$refs.tournamentSendAddRequestValidationObserver) {
      this.formObserver = this.$refs.tournamentSendAddRequestValidationObserver as InstanceType<typeof ValidationObserver>
    }
    this.setDefaultGameAccount()
  }

  get tournament (): ITournamentEntity | null {
    if (this.data.tournament) {
      return this.data.tournament
    }
    return null
  }

  private setDefaultGameAccount () {
    if (this.gameAccounts.length > 0) {
      this.form.my_game_account_id = this.gameAccounts[0].id
    }
  }

  private async onSendAddRequestAction () {
    if (this.tournament === null) return

    this.isLoading = true
    this.isProcessing = true
    const joinToTournamentResult = await accountService.joinParticipantToTournament(this.tournament.id, this.form)

    if (joinToTournamentResult.isFailure()) {
      NotificationService.showErrorNotification(joinToTournamentResult.error.message)
      this.isLoading = false
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully send add request to the tournament')
      this.isLoading = false
      if (this.data.withRedirect) {
        await this.$router.push({ name: RouteNames.TournamentRoute, params: { tournamentId: this.tournament.id.toString() } })
      } else {
        await CurrentTournamentStoreModule.refreshCurrentTournamentParticipants()
      }
      await PopupsStoreModule.hideActivePopup()
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
