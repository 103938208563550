import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const createTournamentRoute: RouteConfig = {
    path: '/tournaments/create',
    name: RouteNames.CreateTournamentRoute,
    component: () => import(/* webpackChunkName: "CreateTournamentPage" */ '@/views/tournaments/CreateTournamentPage.vue'),
    meta: {
        requiresAuth: AuthGuard.MUST_BE_AUTHENTICATED
    }
}