import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {StoreNamespace} from "@/store/types";
import store from "@/store/store";
import {IPopupsModuleState, PopupsModuleGetterTypes, PopupsModuleMutationTypes,} from "@/store/modules/popups/types";
import {popupsStoreConfig} from "@/core/popups/config";
import {IPopupsData, ISetPopupData, IShowPopupDto, PopupsQueueType} from "@/core/popups/types";
import {
    getCurrentActivePopup,
    getNearestPopupInQueue,
    getNextPopupInQueue,
    getPopupByType,
    validatePopupState
} from "@/core/popups/helpers";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Popups,
    store
})
class PopupsModule extends VuexModule implements IPopupsModuleState {
    popupsData: IPopupsData[] = popupsStoreConfig
    currentPopupsQueueType: PopupsQueueType = PopupsQueueType.None
    currentPopupsQueueOrderIndex: number = 0

    get [PopupsModuleGetterTypes.POPUPS_DATA] () { return this.popupsData }

    @Mutation
    [PopupsModuleMutationTypes.UPDATE_POPUPS_DATA] (popupData: IPopupsData) {
        const index = this.popupsData.findIndex((popup) => popup.type === popupData.type)
        if (index !== -1) {
            this.popupsData.splice(index, 1, {...popupData, ...{ queue: this.popupsData[index].queue, order: this.popupsData[index].order }})
        }
    }

    @Mutation
    [PopupsModuleMutationTypes.SET_POPUP_DATA] (popupData: ISetPopupData) {
        const index = this.popupsData.findIndex((popup) => popup.type === popupData.popupType)
        if (index !== -1) {
            this.popupsData.splice(index, 1, {...this.popupsData[index], ...{ data: popupData.data }})
        }
    }

    @Mutation
    [PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_TYPE] (currentPopupsQueueType: PopupsQueueType) {
        this.currentPopupsQueueType = currentPopupsQueueType
    }

    @Mutation
    [PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_ORDER_INDEX] (currentPopupsQueueOrderIndex: number) {
        this.currentPopupsQueueOrderIndex = currentPopupsQueueOrderIndex
    }

    @Action
    showPopup (payload?: IShowPopupDto) {
        const popup = getCurrentActivePopup(this.popupsData)

        if (popup.isSuccess()) {
            // hide active popup
            this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                type: popup.value.type,
                isActive: false,
                isInstantiated: false,
                data: {}
            })
        }

        if (payload) {
            if (!payload.data) {
                payload.data = {}
            }

            const popupToShowResult = getPopupByType(this.popupsData, payload.popupType)

            if (popupToShowResult.isSuccess()) {
                if (popupToShowResult.value.order === 0 && popupToShowResult.value.queue !== PopupsQueueType.None) {
                    if (validatePopupState(popupToShowResult.value.type)) {
                        this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                            type: popupToShowResult.value.type,
                            isActive: true,
                            isInstantiated: true,
                            data: popupToShowResult.value.data
                        })

                        this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_ORDER_INDEX, popupToShowResult.value.order)
                        this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_TYPE, popupToShowResult.value.queue)
                    } else {
                        // get the nearest index to show
                        const nearestPopupInQueueResult = getNearestPopupInQueue(this.popupsData, popupToShowResult.value.queue, popupToShowResult.value.order)

                        if (nearestPopupInQueueResult.isSuccess()) {
                            this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                                type: nearestPopupInQueueResult.value.type,
                                isActive: true,
                                isInstantiated: true,
                                data: nearestPopupInQueueResult.value.data
                            })

                            this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_ORDER_INDEX, nearestPopupInQueueResult.value.order)
                            this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_TYPE, nearestPopupInQueueResult.value.queue)
                        }
                    }
                } else {
                    this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                        type: payload.popupType,
                        isActive: true,
                        isInstantiated: true,
                        data: payload.data
                    })
                }
            }
        } else {
            if (this.currentPopupsQueueType !== PopupsQueueType.None) {
                const nextPopupInQueueResult = getNextPopupInQueue(this.popupsData, this.currentPopupsQueueType, this.currentPopupsQueueOrderIndex)

                if (nextPopupInQueueResult.isSuccess()) {
                    this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                        type: nextPopupInQueueResult.value.type,
                        isActive: true,
                        isInstantiated: true,
                        data: nextPopupInQueueResult.value.data
                    })
                    this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_ORDER_INDEX, nextPopupInQueueResult.value.order)
                }
            }
        }
    }

    @Action
    hideActivePopup (): Promise<any> {
        const popup = getCurrentActivePopup(this.popupsData)

        if (popup.isSuccess()) {
            // hide active popup
            this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                type: popup.value.type,
                isActive: false,
                isInstantiated: false,
                data: {}
            })
        }

        if (this.currentPopupsQueueType !== PopupsQueueType.None) {
            const nextPopupInQueueResult = getNextPopupInQueue(this.popupsData, this.currentPopupsQueueType, this.currentPopupsQueueOrderIndex)

            if (nextPopupInQueueResult.isSuccess()) {
                this.context.commit(PopupsModuleMutationTypes.UPDATE_POPUPS_DATA, {
                    type: nextPopupInQueueResult.value.type,
                    isActive: true,
                    isInstantiated: true,
                    data: nextPopupInQueueResult.value.data
                })

                this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_ORDER_INDEX, nextPopupInQueueResult.value.order)
            } else {
                this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_ORDER_INDEX, -1)
                this.context.commit(PopupsModuleMutationTypes.SET_CURRENT_POPUP_QUEUE_TYPE, PopupsQueueType.None)
            }
        }

        return Promise.resolve()
    }

    @Action
    setPopupData (popupData: ISetPopupData) {
        this.context.commit(PopupsModuleMutationTypes.SET_POPUP_DATA, {
            type: popupData.popupType,
            data: popupData.data
        })
    }
}

export const PopupsStoreModule = getModule(PopupsModule)