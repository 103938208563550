






















import {Component, Emit} from "vue-property-decorator";
import Vue from "vue";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {LeagueOfLegendsRegions} from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import {ICreateLeagueOfLegendsGameAccountRequest} from "@/services/account/types";

@Component({
  components: {
    ValidationProvider
  }
})
export default class ConnectLeagueOfLegendsAccountForm extends Vue {
  private lolRegions: string[] = [
    LeagueOfLegendsRegions.BR1,
    LeagueOfLegendsRegions.EUN1,
    LeagueOfLegendsRegions.EUW1,
    LeagueOfLegendsRegions.JP1,
    LeagueOfLegendsRegions.KR,
    LeagueOfLegendsRegions.LA1,
    LeagueOfLegendsRegions.LA2,
    LeagueOfLegendsRegions.NA1,
    LeagueOfLegendsRegions.OC1,
    LeagueOfLegendsRegions.RU,
    LeagueOfLegendsRegions.TR1,
  ]

  private form: ICreateLeagueOfLegendsGameAccountRequest = {
    summonerName: '',
    regionCode: LeagueOfLegendsRegions.EUW1
  }

  @Emit()
  onRequestDataChange () {
    return this.form
  }
}
