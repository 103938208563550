





















import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentCardParticipantsTableData} from "../TournamentCard/TournamentCardParticipantsTable.vue";
import {accountService} from "@/services";
import {NotificationService} from "../../Notifications/notification.service";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {CurrentTournamentStoreModule, PopupsStoreModule} from "@/store";

export interface ITournamentAcceptAddRequestPopupData {
  tournament: ITournamentEntity
  participant: ITournamentCardParticipantsTableData
}

@Component({})
export default class TournamentAcceptAddRequestPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly data!: ITournamentAcceptAddRequestPopupData

  private isProcessing: boolean = false

  private async acceptTournamentParticipantInviteRequestAction () {
    if (!this.data.tournament || !this.data.participant) return

    let my_game_account_id: number | undefined = undefined
    if (this.data.tournament.my_participation) {
      my_game_account_id = this.data.tournament.my_participation.game_account_id
    }

    this.isProcessing = true
    const acceptTournamentParticipantInviteRequestResult = await accountService.acceptTournamentParticipantInviteRequest(
        this.data.tournament.id,
        this.data.participant.id,
        { my_game_account_id: my_game_account_id })

    if (acceptTournamentParticipantInviteRequestResult.isFailure()) {
      NotificationService.showErrorNotification(acceptTournamentParticipantInviteRequestResult.error.message)
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully accepted invite request')
      CurrentTournamentStoreModule.refreshCurrentTournamentParticipants().then(() => {
        this.onCloseAction()
      })
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
