






import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  components : {BaseButtonRounded}
})
export default class ConnectGameAccountButton extends Vue {
  @Prop({ type: Boolean, required: false, default: () => true }) private readonly block!: boolean
  @Prop({ type: Boolean, required: false, default: () => true }) private readonly xSmall!: boolean
  @Prop({ type: Boolean, required: false, default: () => false }) private readonly small!: boolean

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  get buttonText () {
    if (this.gameAccounts.length > 0) {
      return 'connect-game-acc'
    }

    return 'connect-first-game-acc'
  }

  private onAddNewAccount () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.ConnectGameAccount
    })
  }
}
