import Vue from 'vue'
import VueRouter from 'vue-router'
import {routes} from "@/router/routes";
import {initializationGuard} from "@/router/guards/initialization.guard";
import {authenticationGuard} from "@/router/guards/authentication.guard";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// set router guards
router.beforeEach(initializationGuard)
router.beforeEach(authenticationGuard)

export default router
