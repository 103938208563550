








import { Component, Vue } from 'vue-property-decorator'
import {RouteNames} from "@/router/types";
import { COLORS } from "@/core/helpers/color.types"

@Component({
  enums: {
    RouteNames,
    COLORS
  }
})
export default class DefaultLogo extends Vue {

}
