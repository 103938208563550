import { ISkillFeature } from "@/components/stats/lol/ScoreTracer/UniqueChamps/types";
import { IndividualPosition } from "./leagueOfLegends.enums";

export enum LeagueOfLegendsRegions {
    BR1 = 'BR1',
    EUN1 = 'EUN1',
    EUW1 = 'EUW1',
    JP1 = 'JP1',
    KR = 'KR',
    LA1 = 'LA1',
    LA2 = 'LA2',
    NA1 = 'NA1',
    OC1 = 'OC1',
    RU = 'RU',
    TR1 = 'TR1'
}
interface Characteristic {
    label: string
}

export interface IChampionCharacteristic extends Characteristic {
    championName: string,
}

export interface IPositionCharacteristic extends Characteristic {
    tag: IndividualPosition
}

export interface ILevelCharacteristic extends Characteristic {
    level: number
}

export interface IWnrCharacteristic extends Characteristic {
    avg_wnr: number,
    matches: Array<IGraphMatchData>
}

export interface IGraphMatchData {
    champion_name: string,
    match_id: string,
    remake: boolean,
    win: true
}

export interface IRankedInfo {
    leaguePoints: number,
    losses: number,
    rank: string,
    tier: string,
    wins: number
}

export interface ISpecialChamp {
    avg_champion_score: number,
    avg_champion_score_label: number,
    champion_id: number,
    champion_name: string,
    features: Array<ISkillFeature>,
    individual_position: string,
    pool_count: number,
    samples_count: number,
    wnr: number
}

export interface IChampionsData {
    best: ISpecialChamp,
    worst: ISpecialChamp
}

export interface ICharacteristics {
    champion_characteristic: IChampionCharacteristic,
    individual_position_characteristic: IPositionCharacteristic,
    level_characteristic: ILevelCharacteristic,
    wnr_characteristic: IWnrCharacteristic
}

export interface IGameAccountMatch {
    champion_id: number,
    champion_skill_features: Array<ISkillFeature>,
    champion_skill_score: number,
    champion_training_id: number,
    game_account_id: number,
    game_creation_time: string,
    id: string,
    individual_position: string,
    match_id: number,
    matchup_score: number,
    participant_id: number,
    remake: boolean,
    win: boolean
}

export interface IRunePerk {
    perk: number,
    var1: number,
    var2: number,
    var3: number
}

export interface IPerkStyle {
    description: string,
    style: number,
    selections: Array<IRunePerk>
}

export interface IPlayerPerks {
    statsPerks: {
        defense: number,
        flex: number,
        offense: number
    },
    styles: Array<IPerkStyle>
}

export interface ILolMatchParticipant {
    assists: number,
    baronKills: number,
    bountyLevel: number,
    champExperience: number,
    champLevel: number,
    championId: number,
    championName: string,
    championTransform: string,
    consumablesPurchased: string,
    damageDealtToBuildings: string,
    damageDealtToObjectives: string,
    damageDealtToTurrets: string,
    damageSelfMitigated: string,
    deaths: number,
    detectorWardsPlaced: string,
    doubleKills: number,
    dragonKills: string,
    firstBloodAssist: boolean,
    firstBloodKill: boolean,
    firstTowerAssist: boolean,
    firstTowerKill: boolean,
    gameEndedInEarlySurrender: boolean,
    gameEndedInSurrender: boolean,
    goldEarned: number,
    goldSpent: number,
    individualPosition: string,
    inhibitorKills: number,
    inhibitorTakedowns: number,
    inhibitorsLost: number,
    item0: number,
    item1: number,
    item2: number,
    item3: number,
    item4: number,
    item5: number,
    item6: number,
    itemsPurchased: number,
    killingSprees: number,
    kills: number,
    lane: string,
    largestCriticalStrike: number,
    largestKillingSpree: number,
    largestMultiKill: number,
    longestTimeSpentLiving: number,
    magicDamageDealt: number,
    magicDamageDealtToChampions: number,
    magicDamageTaken: number,
    neutralMinionsKilled: number,
    nexusKills: number,
    nexusLost: number,
    nexusTakedowns: number,
    objectivesStolen: number,
    objectivesStolenAssists: number,
    participantId: number,
    pentaKills: number,
    perks: IPlayerPerks,
    physicalDamageDealt: number,
    physicalDamageDealtToChampions: number,
    physicalDamageTaken: number,
    profileIcon: number,
    puuid: string,
    quadraKills: number,
    riotIdName: string,
    riotIdTagline: string,
    role: string,
    sightWardsBoughtInGame: number,
    spell1Casts: number,
    spell2Casts: number,
    spell3Casts: number,
    spell4Casts: number,
    summoner1Casts: number,
    summoner1Id: number,
    summoner2Casts: number,
    summoner2Id: number,
    summonerId: string,
    summonerLevel: number,
    summonerName: string,
    teamEarlySurrendered: boolean,
    teamId: number,
    teamPosition: string,
    timeCCingOthers: number,
    timePlayed: number,
    totalDamageDealt: number,
    totalDamageDealtToChampions: number,
    totalDamageShieldedOnTeammates: number,
    totalDamageTaken: number,
    totalHeal: number,
    totalHealsOnTeammates: number,
    totalMinionsKilled: number,
    totalTimeCCDealt: number,
    totalTimeSpentDead: number,
    totalUnitsHealed: number,
    tripleKills: number,
    trueDamageDealt: number,
    trueDamageDealtToChampions: number,
    trueDamageTaken: number,
    turretKills: number,
    turretTakedowns: number,
    turretsLost: number,
    unrealKills: number,
    visionScore: number,
    visionWardsBoughtInGame: number,
    wardsKilled: number,
    wardsPlaced: number,
    win: boolean
}

export interface IObjectiveInfo {
    first: boolean,
    kills: number
}

export interface ILolMatchTeam {
    win: boolean,
    teamId: number,
    bans: Array<{
        championId: number,
        pickTurn: number
    }>,
    objectives: {
        baron: IObjectiveInfo,
        champion: IObjectiveInfo,
        dragon: IObjectiveInfo,
        inhibitor: IObjectiveInfo,
        riftHerald: IObjectiveInfo,
        tower: IObjectiveInfo
    }
}

export interface ILolMatch {
    tier: string,
    rank: string,
    match_id: number,
    lol_match_id: string,
    game_creation_time: string,
    game_account_matches: Array<IGameAccountMatch>
    data: {
        metadata: {
            dataVersion: string,
            matchId: string,
            participants: Array<string>
        },
        info: {
            gameCreation: number,
            gameDuration: number,
            gameId: number,
            gameMode: string,
            gameName: string,
            gameStartTimestamp: number,
            gameType: string,
            gameVersion: string,
            mapId: number,
            participants: Array<ILolMatchParticipant>,
            platformId: string,
            queueId: string,
            tournamentCode: string,
            teams: Array<ILolMatchTeam>
        }
    }
}

export interface IScore {
    draft_score: {
        label: string
    },
    impact_score: {
        label: string
    },
    individual_champion_skill_score: {
        label: number
    }
}

export interface ISummoner {
    game_id: number,
    id: number,
    insert_time: number,
    lol_account_id: string,
    lol_id: string,
    name: string,
    profile_icon_id: number,
    puuid: string,
    ranked_flex: IRankedInfo,
    ranked_solo: IRankedInfo,
    region_code: string,
    revision_date: string,
    riot_account_puuid: string,
    summoner_level: number,
    system_account_id: string,
    update_time: number
}
