


















import {Component, Emit, Prop} from "vue-property-decorator";
import Vue from "vue";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import ConnectGameAccountForm from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountForm.vue";
import {GameType} from "@/core/games/types";
import VerifyLeagueOfLegendsAccountPopup
  from "@/components/GameAccounts/ConnectGameAccount/leagueOfLegends/VerifyLeagueOfLegendsAccountPopup.vue";
import {ICreateLeagueOfLegendsGameAccountRequest} from "@/services/account/types";
import {IAuthUser} from "@/core/auth/types";

export interface IConnectGameAccountFormData {
  gameType: GameType,
  formData: ICreateLeagueOfLegendsGameAccountRequest
}

@Component({
  components: {VerifyLeagueOfLegendsAccountPopup, ConnectGameAccountForm},
  enums: {
    GameType
  }
})
export default class ConnectGameAccountPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  private onConnectGameAccountClick (payload: IConnectGameAccountFormData) {
    this.onGameAccountConnected(payload)
  }

  @Emit()
  private onCloseAction () {
    return
  }

  @Emit()
  private onGameAccountConnected (payload: IConnectGameAccountFormData): IConnectGameAccountFormData {
    return payload
  }
}
