











import {Component, Vue} from 'vue-property-decorator'
import {RouteNames} from "@/router/types";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {IAuthUser} from "@/core/auth/types";
import {SidebarsStoreModule} from "@/store";

@Component({
  enums: {
    RouteNames
  }
})
export default class HeaderUserAvatar extends Vue {
  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  private toggleRightSidebar () {
    SidebarsStoreModule.toggleRightSidebar()
  }
}
