import {VuexState} from "@/core/vuex/types";
import {ITournamentForm} from "@/core/tournaments/tournament-form.entity";

export interface ICreateTournamentModuleState {
    tournamentForm: ITournamentForm | null
    currentVuexState: VuexState
}

export enum CreateTournamentModuleGetterTypes {
    CURRENT_VUEX_STATE = 'CURRENT_VUEX_STATE',
    TOURNAMENT_FORM = 'TOURNAMENT_FORM'
}

export enum CreateTournamentsModuleMutationTypes {
    SET_CURRENT_VUEX_STATE = 'SET_CURRENT_VUEX_STATE',
    SET_TOURNAMENT_FORM = 'SET_TOURNAMENT_FORM'
}