import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {StoreNamespace} from "@/store/types";
import store from "@/store/store";
import {
    IUserProfileModuleState,
    UserProfileModuleGetterTypes,
    UserProfileModuleMutationTypes
} from "@/store/modules/userProfile/types";
import {accountsService} from "@/services";
import {
    defaultVuexData,
    defaultVuexPaginatedData,
    IUpdatePaginationData,
    IVuexData,
    IVuexPaginatedData,
    VuexState
} from "@/core/vuex/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {Result} from "typescript-result";
import {AccountStoreModule} from "@/store";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {ITournamentResponse} from "@/services/tournaments/types";
import {IPagination, IPaginationParams} from "@/core/service/types";
import {TournamentTypes} from "@/core/tournaments/types";
import {TournamentsModuleMutationTypes} from "@/store/modules/tournaments/types";
import {map} from "lodash";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.UserProfile,
    store
})
class UserProfileModule extends VuexModule implements IUserProfileModuleState {
    accountData: IVuexData<IAccountEntity | null> = defaultVuexData
    tournamentsData: IVuexPaginatedData<ITournamentResponse[]> = defaultVuexPaginatedData

    get [UserProfileModuleGetterTypes.ACCOUNT] () { return this.accountData.data }

    get [UserProfileModuleGetterTypes.ACCOUNT_VUEX_STATE] () { return this.accountData.vuexState }

    get [UserProfileModuleGetterTypes.ACCOUNT_TOURNAMENTS] () {
        return map(this.tournamentsData.rows, (tournamentResponse) => {
            return {
                ...tournamentResponse.tournament,
                ...{ my_participation: tournamentResponse.data.my_participation,
                    current_participants: tournamentResponse.data.current_participants
                }
            }
        })
    }

    get [UserProfileModuleGetterTypes.ACCOUNT_TOURNAMENTS_PAGINATION] () {
        return this.tournamentsData.pagination
    }

    get [UserProfileModuleGetterTypes.ACCOUNT_TOURNAMENTS_VUEX_STATE] () {
        return this.tournamentsData.vuexState
    }

    @Mutation
    [UserProfileModuleMutationTypes.SET_ACCOUNT] (account: IAccountEntity | null) {
        this.accountData = {...this.accountData, ...{ data: account }}
    }

    @Mutation
    [UserProfileModuleMutationTypes.SET_ACCOUNT_VUEX_STATE] (vuexState: VuexState) {
        this.accountData = {...this.accountData, ...{ vuexState: vuexState }}
    }

    @Mutation
    [UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS] (tournaments: ITournamentResponse[]) {
        this.tournamentsData = {...this.tournamentsData, ...{ rows: tournaments }}
    }

    @Mutation
    [UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_PAGINATION] (pagination: IPagination) {
        this.tournamentsData = {...this.tournamentsData, ...{ pagination: pagination }}
    }

    @Mutation
    [UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_VUEX_STATE] (vuexState: VuexState) {
        this.tournamentsData = {...this.tournamentsData, ...{ vuexState: vuexState }}
    }

    @Action
    public async initialize (userId: string): Promise<Result<null, IAccountEntity>> {
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_VUEX_STATE, VuexState.LOADING)
        const accountResult = await accountsService.getAccountById(userId)

        if (accountResult.isFailure()) {
            this.clearStore()
            return Result.error(null)
        } else {
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT, accountResult.value)
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_VUEX_STATE, VuexState.INITIALIZED)
            await this.setTournaments()
            return Result.ok(accountResult.value)
        }
    }

    @Action
    public async initializeWithCurrentAccount (): Promise<Result<null, IAccountEntity>> {
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_VUEX_STATE, VuexState.LOADING)

        if (AccountStoreModule[AccountModuleGetterTypes.ACCOUNT] === null) {
            this.clearStore()
            return Result.error(null)
        } else {
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT, AccountStoreModule[AccountModuleGetterTypes.ACCOUNT])
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_VUEX_STATE, VuexState.INITIALIZED)
            await this.setTournaments()
            return Result.ok(AccountStoreModule[AccountModuleGetterTypes.ACCOUNT] as IAccountEntity)
        }
    }

    @Action
    private async setTournaments (pagination?: IPaginationParams): Promise<Result<null, ITournamentResponse[]>> {
        const getTournamentsByAccountIdResult = await accountsService.getTournamentsByAccountId(this[UserProfileModuleGetterTypes.ACCOUNT]!.id, pagination)

        if (getTournamentsByAccountIdResult.isFailure() ||
            (getTournamentsByAccountIdResult.isSuccess() && getTournamentsByAccountIdResult.value.rows.length === 0)) {
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS, [])
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_VUEX_STATE, VuexState.DATA_NOT_FOUND)
            return Result.error(null)
        } else {
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS, getTournamentsByAccountIdResult.value.rows)
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_PAGINATION, getTournamentsByAccountIdResult.value.pagination)
            this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_VUEX_STATE, VuexState.INITIALIZED)
            return Result.ok(getTournamentsByAccountIdResult.value.rows)
        }
    }

    @Action
    public async onCurrentPaginationPageChange(data: IUpdatePaginationData<TournamentTypes>) {
        this.context.commit(TournamentsModuleMutationTypes.SET_AVAILABLE_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
        await this.setTournaments({
            limit: defaultVuexPaginatedData.pagination.limit,
            page: data.newCurrentPage - 1
        })
    }

    @Action
    public clearStore () {
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT, null)
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_VUEX_STATE, VuexState.LOADING)
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS, [])
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_PAGINATION, defaultVuexPaginatedData.pagination)
        this.context.commit(UserProfileModuleMutationTypes.SET_ACCOUNT_TOURNAMENTS_VUEX_STATE, VuexState.LOADING)
    }
}

export const UserProfileStoreModule = getModule(UserProfileModule)