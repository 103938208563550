import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {ITournamentParticipantMatchEntity} from "@/core/tournaments/tournament-participant-match.entity";

export enum ParticipantRole {
    OWNER = 'OWNER',
    MODERATOR = 'MODERATOR',
    NORMAL = 'NORMAL'
}

export interface ITournamentParticipantEntity {
    id: number
    place: number | null,
    score: number
    match_counter: number
    team_id: number
    game_account_id: number
    role: ParticipantRole
    accepted: boolean
    tournament_id: number
    is_tournament_source: boolean
    defeats_counter: number
    wins_counter: number
    results: number[]
    tournament?: ITournamentEntity
    gameAccount?: IGameAccountEntity
    tournamentMatches?: ITournamentParticipantMatchEntity[]
}