






































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {AccountStoreModule, AuthStoreModule, PopupsStoreModule} from "@/store";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {IUpdateAccountPasswordRequest} from "@/services/account/types";
import {NotificationService} from "@/components/Notifications/notification.service";

@Component({
  components: {ValidationObserver, ValidationProvider}
})
export default class UpdateAccountPasswordPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  private isProcessing: boolean = false
  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: IUpdateAccountPasswordRequest = {
    password: undefined,
    password_confirmation: undefined
  }

  private mounted () {
    if (this.$refs.updatePasswordValidationObserver) {
      this.formObserver = this.$refs.updatePasswordValidationObserver as InstanceType<typeof ValidationObserver>
    }
  }

  private async onUpdatePasswordAction () {
    this.isProcessing = true
    const updatePasswordResult = await AccountStoreModule.updatePassword(this.form)

    if (updatePasswordResult.isFailure()) {
      NotificationService.showErrorNotification(updatePasswordResult.error.message)
      if (updatePasswordResult.error.errors) {
        this.formObserver.setErrors(updatePasswordResult.error.errors)
      }
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully updated password. Please Sign In')
      this.onCloseAction()
      await AuthStoreModule.signOut()
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
