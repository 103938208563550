import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const authSignInRoute: RouteConfig = {
    path: '/auth/signIn',
    name: RouteNames.AuthSignInRoute,
    component: () => import(/* webpackChunkName: "SignInPage" */ '@/views/auth/SignInPage.vue'),
    meta: {
        requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
    }
}
