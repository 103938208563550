import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {CoreModuleGetterTypes, CoreModuleMutationTypes, ICoreModuleState} from "@/store/modules/core/types";
import store from '@/store/store'
import {StoreNamespace} from "@/store/types";
import {
    AccountFriendsModuleStoreModule,
    AccountGameAccountsModuleStoreModule,
    AccountStoreModule,
    PopupsStoreModule,
    TournamentsStoreModule,
    CurrentTournamentStoreModule,
    AccountTournamentsModuleStoreModule, CreateTournamentStoreModule
} from "@/store";
import {PopupType} from "@/core/popups/types";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Core,
    store
})
class CoreModule extends VuexModule implements ICoreModuleState {
    isInitialized: boolean = false
    isLoading: boolean = false

    get [CoreModuleGetterTypes.IS_LOADING] () {
        return this.isLoading
    }

    get [CoreModuleGetterTypes.IS_INITIALIZED] () {
        return this.isInitialized
    }

    @Mutation
    [CoreModuleMutationTypes.SET_LOADING] (value: boolean) {
        this.isLoading = value
    }

    @Mutation
    [CoreModuleMutationTypes.SET_INITIALIZED] (value: boolean) {
        this.isInitialized = value
    }

    @Action
    public setLoading (payload: boolean) {
        this.context.commit(CoreModuleMutationTypes.SET_LOADING, payload)
    }

    @Action
    public setInitialized () {
        this.context.commit(CoreModuleMutationTypes.SET_INITIALIZED, true)
        this.context.commit(CoreModuleMutationTypes.SET_LOADING, false)
    }

    @Action
    public setUninitialized () {
        this.context.commit(CoreModuleMutationTypes.SET_INITIALIZED, false)
        this.context.commit(CoreModuleMutationTypes.SET_LOADING, false)
    }

    @Action
    public async initializeAuthorizedStores () {
        await AccountStoreModule.initialize()
        await AccountGameAccountsModuleStoreModule.initialize()
        await AccountFriendsModuleStoreModule.initialize()
        return Promise.resolve()
    }

    @Action
    public afterAuthentication () {
        PopupsStoreModule.showPopup({
            popupType: PopupType.Welcome
        })
        return Promise.resolve()
    }

    @Action
    public async clearAuthorizedStores () {
        // account
        await AccountStoreModule.clearStore()
        await AccountFriendsModuleStoreModule.clearStore()
        await AccountGameAccountsModuleStoreModule.clearStore()
        await AccountTournamentsModuleStoreModule.clearStore()

        // tournaments
        await CreateTournamentStoreModule.clearStore()
        await CurrentTournamentStoreModule.clearStore()
        await TournamentsStoreModule.clearStore()
        return Promise.resolve()
    }
}

export const CoreStoreModule = getModule(CoreModule)