



























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import ConnectGameAccountPopup from "@/components/GameAccounts/ConnectGameAccount/ConnectGameAccountPopup.vue";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {AccountFriendsModuleStoreModule, PopupsStoreModule} from "@/store";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {find} from "lodash";
import {NotificationService} from "@/components/Notifications/notification.service";

export interface IDeleteFriendPopupData {
  friendId: string
}

@Component({
  components: {ConnectGameAccountPopup}
})
export default class DeleteFriendPopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean
  @Prop({ type: Object, required: true, default: () => {} }) private readonly data!: IDeleteFriendPopupData

  @Getter(AccountFriendsModuleGetterTypes.FRIENDS, { namespace: StoreNamespace.AccountFriends })
  private readonly friends!: IAccountEntity[]

  private isLoading: boolean = false

  get friend (): IAccountEntity | null {
    const friend = find(this.friends, (friend: IAccountEntity) => friend.id === this.data.friendId)
    if (friend) {
      return friend
    }

    return null
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }

  private async onDeleteAction () {
    if (this.friend === null) {
      return
    }

    this.isLoading = true
    const deleteFriendResult = await AccountFriendsModuleStoreModule.deleteFriend(this.friend.id)

    if (deleteFriendResult.isFailure()) {
      NotificationService.showErrorNotification(deleteFriendResult.error.message)
      this.isLoading = false
    } else {
      NotificationService.showSuccessNotification('Friend successfully deleted')
      this.isLoading = false
      await PopupsStoreModule.hideActivePopup()
    }
  }
}
