
































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {AccountStoreModule, PopupsStoreModule} from "@/store";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {IUpdateAccountDisplayNameRequest} from "@/services/account/types";
import {NotificationService} from "@/components/Notifications/notification.service";

@Component({
  components: {ValidationObserver, ValidationProvider}
})
export default class UpdateAccountDisplayNamePopup extends Vue {
  @Prop({ type: Boolean, required: true, default: () => false }) private readonly isActive!: boolean

  private isProcessing: boolean = false
  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: IUpdateAccountDisplayNameRequest = {
    display_name: undefined
  }

  private mounted () {
    if (this.$refs.updateDisplayNameValidationObserver) {
      this.formObserver = this.$refs.updateDisplayNameValidationObserver as InstanceType<typeof ValidationObserver>
    }
  }

  private async onUpdateDisplayNameAction () {
    this.isProcessing = true
    const updateDisplayNameResult = await AccountStoreModule.updateDisplayName(this.form)

    if (updateDisplayNameResult.isFailure()) {
      NotificationService.showErrorNotification(updateDisplayNameResult.error.message)
      if (updateDisplayNameResult.error.errors) {
        this.formObserver.setErrors(updateDisplayNameResult.error.errors)
      }
      this.isProcessing = false
    } else {
      NotificationService.showSuccessNotification('Successfully updated display name')
      this.onCloseAction()
    }
  }

  private onCloseAction () {
    PopupsStoreModule.hideActivePopup()
  }
}
