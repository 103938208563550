


















import { Component, Vue } from 'vue-property-decorator'
import {SidebarsStoreModule} from "@/store";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {RouteNames} from "@/router/types";
import {IAuthUser} from "@/core/auth/types";
import HeaderUserAvatar from "@/components/Header/HeaderUserAvatar.vue";
import HeaderLogo from "@/components/Header/HeaderLogo.vue";
import { COLORS } from "@/core/helpers/color.types"

@Component({
  components: {HeaderLogo, HeaderUserAvatar},
  enums: {
    RouteNames,
    COLORS
  }
})
export default class DefaultHeader extends Vue {
  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, { namespace: StoreNamespace.Auth })
  private readonly isLoggedIn!: boolean

  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  private toggleLeftSidebar () {
    SidebarsStoreModule.toggleLeftSidebar()
  }
}
