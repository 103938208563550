import Vue from 'vue'

import './plugins/eventBus'
import './plugins/firebase'
import './plugins/vueEnums'
import './plugins/vueCookies'
import './plugins/clipboard.plugin'
import './plugins/moment'

import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

import App from './App.vue'

import {AuthStoreModule} from "@/store";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n,
  created() {
    AuthStoreModule.setupListeners()
  },
}).$mount('#app')
