
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { LolModuleGetterTypes, LolModuleMutationTypes, LolModuleState, ChampsData, LOADING_STATUS, IMostFrequentChampion } from "@/store/modules/lol/types"
import store from '@/store/store'
import { StoreNamespace } from "@/store/types";
import { LolDataDragonAssetsApi } from "@/services/RiotAssets/LolDataDragonAssetsApi";
import { LolStaticApi } from "@/services/RiotAssets/LolStaticApi";
import { lolService } from "@/services"
import { NotificationService } from "@/components/Notifications/notification.service";
import { Result } from "typescript-result";
import { IServiceError } from "@/core/service/types";
import { ISummonerGetResponse } from "@/services/lol/types";
import { ICharacteristics, ILolMatch, ILolMatchParticipant, IScore, ISummoner } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { IItemData, IItemsResponse, IQueueData, IRuneData, ISummonerSPellsData, ISummonerSPellsResponse } from "@/services/RiotAssets/types";
import { AxiosResponse } from "axios";
import { BackgroundChanger } from "@/core/games/BackgroundChanger";

@Module({
    dynamic: true,
    namespaced: true,
    name: StoreNamespace.Lol,
    store
})
class LolModule extends VuexModule implements LolModuleState {
    summoner: ISummoner | null = null;
    matches: Array<ILolMatch> = [];
    characteristic: ICharacteristics | undefined = undefined;
    score: IScore | undefined = undefined;
    championsData: ChampsData | undefined = undefined;
    mostFrequentChampion: IMostFrequentChampion | undefined = undefined;
    backgroundSkinId: number = Math.round(Math.random() * BackgroundChanger.maxImgId);

    status: LOADING_STATUS = LOADING_STATUS.READY;
    message: {statusCode?: number, message?: string} | undefined = undefined;
    lastSearchedRegion: string = "";
    NOfMatchesfirstFetch: number = 10;
    queueListData: IQueueData[] | undefined = undefined;
    summonerSpells: ISummonerSPellsData | undefined =undefined;
    runesData: IRuneData[] | undefined = undefined;
    itemsData: Array<[string, IItemData]> | undefined = undefined;

    get [LolModuleGetterTypes.MESSAGE] () {
        return this.message;
    }

    get [LolModuleGetterTypes.BACKGROUND_SKIN_ID] () {
        return this.backgroundSkinId;
    }

    get [LolModuleGetterTypes.CHAMPIONS_DATA] () {
        return this.championsData;
    }

    get [LolModuleGetterTypes.MATCHES_FIRST_FETCH_N] () {
        return this.NOfMatchesfirstFetch;
    }

    get [LolModuleGetterTypes.LAST_SEARCHED_REGION] () {
        return this.lastSearchedRegion;
    }

    get [LolModuleGetterTypes.IS_LOADING] () {
        return this.status === LOADING_STATUS.LOADING;
    }

    get [LolModuleGetterTypes.IS_READY] () {
        return this.status === LOADING_STATUS.READY;
    }

    get [LolModuleGetterTypes.SUMMONER_ERROR] () {
        return this.summoner === null;
    }

    get [LolModuleGetterTypes.SUMMONER_FOUND] () {
        if (this.summoner)
            return true;
        return false;
    }

    get [LolModuleGetterTypes.EMPTY_SUMMONER] () {
        return this.summoner === undefined;
    }

    get [LolModuleGetterTypes.SUMMONER] () {
        return this.summoner;
    }

    get [LolModuleGetterTypes.PROFILE_ICON] () {
        if (!this.summoner)
            return -1;
        else return this.summoner.profile_icon_id;
    }

    get [LolModuleGetterTypes.MATCHES] () {
        return this.matches;
    }

    get [LolModuleGetterTypes.CHARACTERISTIC] () {
        return this.characteristic;
    }
    
    get [LolModuleGetterTypes.LAST_PLAYED_CHAMPION_NAME] () {
        return this.mostFrequentChampion?.champion.name;
    }

    get [LolModuleGetterTypes.SUMMONER_RANK_SOLO] () {
        if (!this.summoner)
            return undefined;
        return this.summoner.ranked_solo;
    }

    get [LolModuleGetterTypes.SUMMONER_RANK_FLEX] () {
        if (!this.summoner)
            return undefined;
        return this.summoner.ranked_flex;
    }

    get [LolModuleGetterTypes.PLAYER_SCORE] () {
        return this.score;
    }

    get [LolModuleGetterTypes.UPDATE_TIME] () {
        if (!this.summoner)  return -1;
        else                  return this.summoner.update_time * 1000;
  
    }

    get [LolModuleGetterTypes.QUEUE_DATA] () {
        return this.queueListData;
    }

    get [LolModuleGetterTypes.RUNES_DATA] () {
        return this.runesData;
    }

    get [LolModuleGetterTypes.ITEMS_DATA] () {
        return this.itemsData;
    }

    get [LolModuleGetterTypes.SUMMONER_SPELLS] () {
        return this.summonerSpells;
    }

    @Mutation
    [LolModuleMutationTypes.SET_BACKGROUND_SKIN_ID] (value: number) {
        this.backgroundSkinId = value;
    }

    @Mutation
    [LolModuleMutationTypes.SET_CHAMPIONS_DATA] (newChampsData: ChampsData | undefined) {
        this.championsData = newChampsData;
    }

    @Mutation
    [LolModuleMutationTypes.SET_SUMMONER] (summoner: ISummoner | null) {
        this.summoner = summoner;
    }

    @Mutation
    [LolModuleMutationTypes.SET_MATCHES] (matches: Array<ILolMatch>) {
        this.matches = matches;
    }

    @Mutation
    [LolModuleMutationTypes.CALCULATE_LAST_PLAYED_CHAMP] () {
        if (this.summoner === null) {
            this.mostFrequentChampion = undefined;
            return;
        }

        const counterMap = new Map<string, [number, number]>();
    
        for (const matchRecord of this.matches) {
            const participants: ILolMatchParticipant[] = matchRecord.data.info.participants;
            for (const player of participants) {
                if (player.puuid === this.summoner!.puuid) {
                let championValue: any = counterMap.get(player.championName);
                if (championValue)
                    counterMap.set(player.championName, [championValue[0]+1, player.championId])
                else
                    counterMap.set(player.championName, [1, player.championId]);
                }
            }
        }
        
        let champInfo : IMostFrequentChampion = {
            count: 0,
            champion: {
                name: "",
                id: -1
            }
        };
        
        for (const pair of counterMap) {
            if (pair[1][0] >= champInfo.count) {
                champInfo.count = pair[1][0];
                champInfo.champion = {
                    name: pair[0],
                    id: pair[1][1]
                }
            }        
        }
    
        this.mostFrequentChampion = champInfo;
    }

    @Mutation
    [LolModuleMutationTypes.SET_STATUS] (newStatus: LOADING_STATUS) {
        this.status = newStatus;
    }

    @Mutation
    [LolModuleMutationTypes.SET_MESSAGE] (newMessage: any) {
        this.message = newMessage;
    }

    @Mutation
    [LolModuleMutationTypes.SET_CHARACTERISTIC] (newCharacterisic: ICharacteristics) {
        this.characteristic = newCharacterisic;
    }

    @Mutation
    [LolModuleMutationTypes.SET_SCORE] (newScore: IScore) {
        this.score = newScore;
    }

    @Mutation
    [LolModuleMutationTypes.SET_QUEUE_LIST_DATA] (queueData: IQueueData[]) {
        this.queueListData = queueData;
    }

    @Mutation
    [LolModuleMutationTypes.SET_SUMMONER_SPELLS_DATA] (spellsData: any) {
        this.summonerSpells = spellsData;
    }

    @Mutation
    [LolModuleMutationTypes.SET_RUNES_DATA] (runesData: IRuneData[]) {
        this.runesData = runesData;
    }

    @Mutation
    [LolModuleMutationTypes.SET_ITEMS_DATA] (itemsData: Array<[string, IItemData]>) {
        this.itemsData = itemsData;
    }

    @Mutation
    [LolModuleMutationTypes.SET_LAST_REGION] (lastRegion: string) {
        this.lastSearchedRegion = lastRegion;
    }

    @Action
    public async fetchSummoner(reqInfo: {name: string, region: string, forced: number}) {
        const {name, region, forced} = reqInfo;
        console.log("params", name, region, forced);
        if (!name || !region) {
            this.context.commit(LolModuleMutationTypes.SET_SUMMONER, undefined);
            return;
        }
        this.context.commit(LolModuleMutationTypes.SET_STATUS, LOADING_STATUS.LOADING);
        this.context.commit(LolModuleMutationTypes.SET_LAST_REGION, region);
        lolService.getSummoner(name, region, forced, this.NOfMatchesfirstFetch)
        .then(
            (response: Result<IServiceError, ISummonerGetResponse>) => {
                if (response.isFailure()) {
                    this.context.commit(LolModuleMutationTypes.SET_SUMMONER, null);
                    this.context.commit(LolModuleMutationTypes.SET_MESSAGE, response.error);
                    this.context.commit(LolModuleMutationTypes.SET_CHAMPIONS_DATA, undefined);
                    this.context.commit(LolModuleMutationTypes.SET_MATCHES, []);
                    this.context.commit(LolModuleMutationTypes.SET_CHARACTERISTIC, undefined);
                    this.context.commit(LolModuleMutationTypes.SET_SCORE, undefined)
                    this.context.commit(LolModuleMutationTypes.SET_STATUS, LOADING_STATUS.FAILED);
                    this.context.commit(LolModuleMutationTypes.CALCULATE_LAST_PLAYED_CHAMP);
                    return;
                }
                
                this.context.commit(LolModuleMutationTypes.SET_BACKGROUND_SKIN_ID, 0);
                const value: ISummonerGetResponse = response.value;
                this.context.commit(LolModuleMutationTypes.SET_MATCHES, value.matches);
                this.context.commit(LolModuleMutationTypes.SET_SUMMONER, value.summoner);
                this.context.commit(LolModuleMutationTypes.SET_CHAMPIONS_DATA, value.champions_data);
                this.context.commit(LolModuleMutationTypes.SET_SCORE, value.score);
                this.context.commit(LolModuleMutationTypes.SET_CHARACTERISTIC, value.characteristics);
                this.context.commit(LolModuleMutationTypes.CALCULATE_LAST_PLAYED_CHAMP);
                this.context.commit(LolModuleMutationTypes.SET_STATUS, LOADING_STATUS.READY);

                for (const warning of response.value.warnings) {
                    NotificationService.showWarningNotification(`${warning.message}\n${warning.extras}`);
                }
            }
        );
    }

    @Action
    public async changeApiKey(keyString: string) {
        lolService.updateRiotApiKey(keyString)
            .then(
                (result: Result<IServiceError, any>) =>  {

                    if (result.isFailure()) {
                        NotificationService.showErrorNotification('Failed to update api Key:' + result.error);
                    }
                    else {
                        NotificationService.showSuccessNotification('Api Key updated correctly.')
                    }

                }
            )
    }

    @Action
    public async fetchBaseLolInfo() {
        LolStaticApi.getQueue()
        .then(
            (response: any) => {
                this.context.commit(LolModuleMutationTypes.SET_QUEUE_LIST_DATA, response.data);
            }
        )
        .catch(
            (reason: any) => { console.warn("FAILED TO FETCH QUEUES INFO:", reason); }
        );
        LolDataDragonAssetsApi.summonerSpellsJson()
        .then(
            (response: AxiosResponse<any>) => {
                const respData: ISummonerSPellsResponse = response.data;
                this.context.commit(LolModuleMutationTypes.SET_SUMMONER_SPELLS_DATA, respData.data);
        })
        .catch(
            (reason: any) => { console.warn("Failed TO FETCH SUMMONER SPELLS DATA"); }
        );
        LolDataDragonAssetsApi.runesJson()
        .then((response: any) => {
            this.context.commit(LolModuleMutationTypes.SET_RUNES_DATA, response.data);
        })
        .catch(
            (reason: any) => { console.warn("Failed TO FETCH RUNES DATA"); }
        );
        LolDataDragonAssetsApi.itemsJson()
        .then(
            (response: any) => {
                console.log(response.data);
                const responseData: IItemsResponse = response.data;
                this.context.commit(LolModuleMutationTypes.SET_ITEMS_DATA, Object.entries(responseData.data));
            }
        )
        .catch(
            (reason: any) => { console.warn("Failed TO FETCH Items DATA"); }
        );
    }
}

export const LolStoreModule = getModule(LolModule)