import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {Result} from "typescript-result";
import {ICreateTournamentRequest} from "@/services/account/tournament.types";
import {IPaginationParams, IServiceError} from "@/core/service/types";
import {TournamentTypes} from "@/core/tournaments/types";
import {IUpdatePaginationData, IVuexPaginatedData} from "@/core/vuex/types";
import {ITournamentResponse} from "@/services/tournaments/types";

export interface IAccountTournamentsModuleState {
    participatedTournamentsData: IVuexPaginatedData<ITournamentResponse[]>
    ownedTournamentsData: IVuexPaginatedData<ITournamentResponse[]>
    completedTournamentsData: IVuexPaginatedData<ITournamentResponse[]>
    dataPoolingInterval: NodeJS.Timeout | null

    initializeParticipatedTournaments (): Promise<void>
    initializeOwnedTournaments (): Promise<void>
    initializeCompletedTournaments (): Promise<void>
    setParticipatedTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>>
    setOwnedTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>>
    setCompletedTournaments(pagination?: IPaginationParams): Promise<Result<[], ITournamentResponse[]>>
    createTournament(content: ICreateTournamentRequest): Promise<Result<IServiceError, ITournamentEntity>>
    onCurrentPaginationPageChange (data: IUpdatePaginationData<TournamentTypes>)
    startDataPooling (): void
    stopDataPooling (): void
    clearParticipatedTournaments (): void
    clearOwnedTournaments (): void
    clearCompletedTournaments (): void
    clearStore (): void
}

export enum AccountTournamentsModuleGetterTypes {
    PARTICIPATED_TOURNAMENTS = 'PARTICIPATED_TOURNAMENTS',
    PARTICIPATED_TOURNAMENTS_PAGINATION = 'PARTICIPATED_TOURNAMENTS_PAGINATION',
    PARTICIPATED_TOURNAMENTS_VUEX_STATE = 'PARTICIPATED_TOURNAMENTS_VUEX_STATE',

    OWNED_TOURNAMENTS = 'OWNED_TOURNAMENTS',
    OWNED_TOURNAMENTS_PAGINATION = 'OWNED_TOURNAMENTS_PAGINATION',
    OWNED_TOURNAMENTS_VUEX_STATE = 'OWNED_TOURNAMENTS_VUEX_STATE',

    COMPLETED_TOURNAMENTS = 'COMPLETED_TOURNAMENTS',
    COMPLETED_TOURNAMENTS_PAGINATION = 'COMPLETED_TOURNAMENTS_PAGINATION',
    COMPLETED_TOURNAMENTS_VUEX_STATE = 'COMPLETED_TOURNAMENTS_VUEX_STATE',
}

export enum AccountTournamentsModuleMutationTypes {
    SET_PARTICIPATED_TOURNAMENTS = 'SET_PARTICIPATED_TOURNAMENTS',
    SET_PARTICIPATED_TOURNAMENTS_PAGINATION = 'SET_PARTICIPATED_TOURNAMENTS_PAGINATION',
    SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE = 'SET_PARTICIPATED_TOURNAMENTS_VUEX_STATE',

    SET_OWNED_TOURNAMENTS = 'SET_OWNED_TOURNAMENTS',
    SET_OWNED_TOURNAMENTS_PAGINATION = 'SET_OWNED_TOURNAMENTS_PAGINATION',
    SET_OWNED_TOURNAMENTS_VUEX_STATE = 'SET_OWNED_TOURNAMENTS_VUEX_STATE',

    SET_COMPLETED_TOURNAMENTS = 'SET_COMPLETED_TOURNAMENTS',
    SET_COMPLETED_TOURNAMENTS_PAGINATION = 'SET_COMPLETED_TOURNAMENTS_PAGINATION',
    SET_COMPLETED_TOURNAMENTS_VUEX_STATE = 'SET_COMPLETED_TOURNAMENTS_VUEX_STATE',

    SET_DATA_POOLING_INTERVAL = 'SET_DATA_POOLING_INTERVAL'
}