

















import {Component, Prop, Vue} from "vue-property-decorator";
import MyGameAccountsList from "@/components/GameAccounts/MyGameAccountsList.vue";
import { AuthModuleGetterTypes } from "@/store/modules/auth/types";
import { StoreNamespace } from "@/store/types";
import { Getter } from "vuex-class";
import AccountFriendsSocialSidebarList from "@/components/Friends/AccountFriendsSocialSidebarList.vue";
import SidebarUserAvatar from "@/components/Sidebar/SidebarUserAvatar.vue";
import {SidebarsStoreModule} from "@/store";
import {SidebarsModuleGetterTypes} from "@/store/modules/sidebars/types";
import {RouteNames} from "@/router/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"
import { COLORS } from "@/core/helpers/color.types"

@Component({
  components: {SidebarUserAvatar, AccountFriendsSocialSidebarList, MyGameAccountsList, BaseButtonRounded},
  enums: {
    RouteNames,
    COLORS
  }
})
export default class SocialSidebar extends Vue {
  @Prop({required: true}) private readonly color!: COLORS;

  @Getter(SidebarsModuleGetterTypes.IS_RIGHT_SIDEBAR_OPEN, { namespace: StoreNamespace.Sidebars })
  private readonly isSidebarOpen!: boolean

  @Getter(AuthModuleGetterTypes.IS_LOGGED_IN, {namespace: StoreNamespace.Auth})
  private readonly isLoggedIn!: boolean

  private onSidebarInput (val: boolean) {
    SidebarsStoreModule.toggleRightSidebar(val)
  }
}
