import { RouteConfig } from 'vue-router'
import { AuthGuard, RouteNames } from '@/router/types'

export const homeRoute: RouteConfig = {
    path: '/',
    name: RouteNames.HomeRoute,
    component: () => import(/* webpackChunkName: "HomePage" */ '@/views/HomePage.vue'),
    meta: {
        requiresAuth: AuthGuard.CANNOT_BE_AUTHENTICATED
    }
}