import {BaseAxiosService} from "@/core/service/base.service";
import {IServiceError} from "@/core/service/types";
import { Result } from "typescript-result";
import { ISummonerGetResponse, LolServiceUrlPaths } from "./types";

export class LolService extends BaseAxiosService {

    constructor() {
        super(process.env.VUE_APP_LOL_SERVICE_URL as string)
    }

    public static create () {
        return new LolService();
    }

    public getSummoner (summonerName: string, region: string, forced: number, matchesCount: number): Promise<Result<IServiceError, ISummonerGetResponse>> {
        return this.executeGet<any>(
            LolServiceUrlPaths.GetSummonerURL
                .replace('{summonerName}', summonerName)
                .replace('{regionCode}', region)
                .replace('{forced}', forced.toString())
                .replace('{matchesCount}', matchesCount.toString())
        )
    }

    public getMatches(summonerName: string, regionCode: string): Promise<Result<IServiceError, any>> {
        return this.executeGet<any>(
            LolServiceUrlPaths.GetMatchesURL
                .replace('{summonerName}', summonerName)
                .replace('{regionCode}', regionCode)
        );
    }

    public updateRiotApiKey (keyString: string): Promise<Result<IServiceError, any>> {
        return this.executeWithPatchContent<any>(
            LolServiceUrlPaths.PatchRiotApiKeyURL
                .replace('{riotApiKey}', keyString),
            {}
        )
    }

}
