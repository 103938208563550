import {ICoreModuleState} from "@/store/modules/core/types";
import {IAuthModuleState} from "@/store/modules/auth/types";
import {IPopupsModuleState} from "@/store/modules/popups/types";
import {IAccountModuleState} from "@/store/modules/account/types";
import {IAccountFriendsModuleState} from "@/store/modules/account/friends/types";
import {IUserProfileModuleState} from "@/store/modules/userProfile/types";
import {ITournamentsModuleState} from "@/store/modules/tournaments/types";

export interface IRootState {
    core: ICoreModuleState,
    auth: IAuthModuleState,
    popups: IPopupsModuleState,
    account: IAccountModuleState,
    accountFriends: IAccountFriendsModuleState,
    userProfile: IUserProfileModuleState,
    tournaments: ITournamentsModuleState,
    state: {
        lastVisitTimestamp: number
    }
}

export enum StoreNamespace {
    Core = 'core',
    Auth = 'auth',
    Popups = 'popups',
    Account = 'account',
    AccountFriends = 'accountFriends',
    AccountGameAccounts = 'accountGameAccounts',
    AccountTournaments = 'accountTournaments',
    Lol = 'lol',
    UserProfile = 'userProfile',
    Tournaments = 'tournaments',
    CurrentTournament = 'currentTournament',
    CreateTournament = 'createTournament',
    Sidebars = 'sidebars'
}